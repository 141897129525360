import { Button, Input, Select } from 'antd';
import React from 'react';

const { Search } = Input;

interface SearchProps {
  selectedFilterValue: SelectedSalesPeopleFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedSalesPeopleFilterValue>>;
  getData: () => void;
}

const SalesPeopleFilterSelector = ({ selectedFilterValue, setSelectedFilterValue, getData }: SearchProps) => {
  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      name: null,
      phoneNumber: null,
      safetyNumber: null,
      isDelete: null,
    });
  };
  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Search
          className="mr-4"
          placeholder="영업 담당자 이름"
          allowClear
          onSearch={getData}
          style={{ width: 180 }}
          value={selectedFilterValue?.name || ''}
          onChange={(e) => handleInputStringChange('name', e.target.value)}
        />
        <Search
          className="mr-4"
          placeholder="전화번호 입력"
          allowClear
          onSearch={getData}
          style={{ width: 180 }}
          value={selectedFilterValue?.phoneNumber || ''}
          onChange={(e) => handleInputStringChange('phoneNumber', e.target.value)}
        />
        <Search
          className="mr-4"
          placeholder="안심번호 입력"
          allowClear
          onSearch={getData}
          style={{ width: 180 }}
          value={selectedFilterValue?.safetyNumber || ''}
          onChange={(e) => handleInputStringChange('safetyNumber', e.target.value)}
        />
        <Select
          style={{ width: 100 }}
          placeholder="삭제여부"
          optionFilterProp="children"
          options={[
            { label: '삭제', value: true },
            { label: '미삭제', value: false },
          ]}
          value={selectedFilterValue.isDelete}
          onChange={(value) => onChangeSelect('isDelete', value)}
        />
      </div>
      <div className="w-full flex justify-end mb-2">
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default SalesPeopleFilterSelector;
