import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';

import { postLogin } from '@/api/public';
import useFetchUserInfo from '@/hooks/useFetchUserInfo';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface LoginInfo {
  loginId: string;
  password: string;
  [key: string]: string;
}

const Login = () => {
  const navigate = useNavigate();
  const fetchUserInfo = useFetchUserInfo();
  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    loginId: '',
    password: '',
  });

  const onFinish = () => {
    loginMutation.mutate(loginInfo);
  };

  const loginMutation = useMutation(postLogin, {
    onSuccess: (response) => {
      const { token } = response.data;
      fetchUserInfo(token, true);
      message.success('로그인 되었습니다.', 2);
    },
    onError: (error: AxiosError) => {
      if (error.code === 'AUTHENTICATION_FAIL') {
        message.error('아이디와 비밀번호를 다시 확인해주세요.', 2);
      } else {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      }
    },
  });

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginInfo({ ...loginInfo, [name]: value });
  };

  return (
    <div style={{ minHeight: '100vh', minWidth: '756px', height: '100vh' }}>
      <div className="flex flex-col items-center justify-center h-screen p-4">
        <h1 className="py-8 text-2xl">직트럭 관리자 페이지</h1>
        <Form
          name="normal_login"
          className="login-form w-[400px]"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="loginId"
            rules={[{ required: true, message: '아이디를 입력해주세요.' }]}
            style={{ marginBottom: '28px' }}
          >
            <Input
              name="loginId"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="아이디"
              value={loginInfo.loginId}
              onChange={onChangeInput}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
            style={{ marginBottom: '28px' }}
          >
            <Input
              name="password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="비밀번호"
              value={loginInfo.password}
              onChange={onChangeInput}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: '28px' }}>
            <Button type="primary" htmlType="submit" className="login-form-button bg-[#1890ff] w-full">
              로그인
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
