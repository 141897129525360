import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
const { RangePicker } = DatePicker;
interface SearchProps {
  selectedFilterValue: SelectedCallingHistoryFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedCallingHistoryFilterValue>>;
  getCallingHistoryData: () => void;
}

const CallingHistoryFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getCallingHistoryData,
}: SearchProps) => {
  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };
  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };
  const clear = () => {
    setSelectedFilterValue({
      calledNumber: null,
      callingNumber: null,
      safetyNumber: null,
      startDate: null,
      endDate: null,
    });
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Input
          className="mr-4"
          placeholder="발신자 전화번호"
          allowClear
          style={{ width: 180 }}
          value={selectedFilterValue?.callingNumber || ''}
          onChange={(e) => handleInputStringChange('callingNumber', e.target.value)}
        />

        <Input
          className="mr-4"
          placeholder="수신자 전화번호"
          allowClear
          style={{ width: 180 }}
          value={selectedFilterValue?.calledNumber || ''}
          onChange={(e) => handleInputStringChange('calledNumber', e.target.value)}
        />

        <Input
          className="mr-4"
          placeholder="안심번호"
          allowClear
          style={{ width: 180 }}
          value={selectedFilterValue?.safetyNumber || ''}
          onChange={(e) => handleInputStringChange('safetyNumber', e.target.value)}
        />
      </div>
      <div className="w-full">
        <span className="mr-2">요청일자</span>
        <RangePicker
          className="min-w-[400px]"
          value={[
            selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
            selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
          ]}
          onChange={handleDateRangeChange}
          placeholder={['시작 날짜', '종료 날짜']}
        />
      </div>

      <div className="w-full flex mb-2">
        <div className="w-full"></div>
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getCallingHistoryData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};
export default CallingHistoryFilterSelector;
