import { adminMenusWithActionsAtom } from '@/store/adminMenusWithActions';
import { DownOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';

interface MenuActionViewProps {
  actionIdList: IdNamePair[];
  updateActionId: (value: number[]) => void;
  roleId: number | null;
}

type CheckedItem = { id: number; type: 'menu' | 'action' };

const superAdministratorId = 1;

const MenuActionView = ({ actionIdList, updateActionId, roleId }: MenuActionViewProps) => {
  const [openMenus, setOpenMenus] = useState<number[]>([]);

  const [adminMenusWithActions] = useAtom(adminMenusWithActionsAtom);
  const [checkedValues, setCheckedValues] = useState<CheckedItem[] | []>([]);

  const isDisabledCheck = roleId === superAdministratorId;

  useEffect(() => {
    if (adminMenusWithActions) {
      setOpenMenus(adminMenusWithActions.map((menu) => menu.id));
    }
  }, [adminMenusWithActions]);

  useEffect(() => {
    if (actionIdList && Array.isArray(actionIdList)) {
      setCheckedValues(() => {
        const newActionValues: CheckedItem[] = actionIdList.map((action) => ({
          id: Number(action),
          type: 'action',
        }));

        const newCheckedValues: CheckedItem[] = [...newActionValues];

        adminMenusWithActions.forEach((menu) => {
          const allChecked = menu.adminActions?.every((action) =>
            newActionValues.some((item) => item.id === action.id),
          );
          if (allChecked) {
            newCheckedValues.push({ id: menu.id, type: 'menu' });
          }
        });

        return newCheckedValues;
      });
    }
  }, [actionIdList, adminMenusWithActions]);

  const isChecked = (id: number, type: 'menu' | 'action') =>
    checkedValues.some((item) => item.id === id && item.type === type);

  const onChangeMenu = (menuId: number, actions: IdNamePair[]) => {
    setCheckedValues((prev) => {
      const isMenuChecked = isChecked(menuId, 'menu');
      const actionIds: CheckedItem[] = actions.map((action) => ({ id: action.id, type: 'action' }));

      if (isMenuChecked) {
        return prev.filter((item) => item.id !== menuId && !actionIds.some((a) => a.id === item.id));
      } else {
        return [...prev, { id: menuId, type: 'menu' }, ...actionIds];
      }
    });
  };

  const onChangeAction = (menuId: number, actionId: number, actions: IdNamePair[]) => {
    setCheckedValues((prev) => {
      const isActionChecked = isChecked(actionId, 'action');
      const updatedValues: CheckedItem[] = isActionChecked
        ? prev.filter((item) => !(item.id === actionId && item.type === 'action'))
        : [...prev, { id: actionId, type: 'action' }];

      const actionIds = actions.map((action) => action.id);
      const checkedActionIds = updatedValues
        .filter((item) => item.type === 'action' && actionIds.includes(item.id))
        .map((item) => item.id);

      if (checkedActionIds.length === actionIds.length) {
        return [...updatedValues, { id: menuId, type: 'menu' }];
      } else {
        return updatedValues.filter((item) => !(item.id === menuId && item.type === 'menu'));
      }
    });
  };

  const handleToggleMenuAction = (menuId: number) => {
    setOpenMenus((prev) => (prev.includes(menuId) ? prev.filter((id) => id !== menuId) : [...prev, menuId]));
  };

  useEffect(() => {
    const actionIds = checkedValues.filter((item) => item.type === 'action').map((item) => item.id);

    if (JSON.stringify(actionIdList) !== JSON.stringify(actionIds)) {
      updateActionId(actionIds);
    }
  }, [checkedValues]);

  if (!adminMenusWithActions) return null;

  return (
    <>
      {adminMenusWithActions.map((menu) => {
        return (
          <div key={menu.id} className="py-2">
            <div className="flex items-center">
              <Checkbox
                onChange={() => onChangeMenu(menu.id, menu.adminActions || [])}
                checked={isChecked(menu.id, 'menu')}
                disabled={isDisabledCheck}
              >
                <span className="text-black">{menu.name}</span>
              </Checkbox>
              <DownOutlined
                className={`cursor-pointer text-gray-500 text-xs ml-2 transition-transform ${
                  openMenus.includes(menu.id) ? 'rotate-180' : ''
                }`}
                onClick={() => handleToggleMenuAction(menu.id)}
              />
            </div>

            {openMenus.includes(menu.id) &&
              menu.adminActions?.map((action) => (
                <div key={action.id} className="px-6 py-1">
                  <Checkbox
                    onChange={() => onChangeAction(menu.id, action.id, menu.adminActions || [])}
                    checked={isChecked(action.id, 'action')}
                    disabled={isDisabledCheck}
                  >
                    <span className="text-black"> {action.name}</span>
                  </Checkbox>
                </div>
              ))}
          </div>
        );
      })}
    </>
  );
};

export default MenuActionView;
