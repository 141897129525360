import apiManager from '../ApiManager';

export const getAdminRole = async (queryParam?: URLSearchParams) => {
  const res = await apiManager.get('/admin/v1/admin-role', { params: queryParam });
  return res.data;
};

export const getAdminRoleDetail = async (id?: number) => {
  const res = await apiManager.get(`/admin/v1/admin-role/${id}`);
  return res.data;
};
