import { Button, Input, Select } from 'antd';
import React, { memo } from 'react';

const { Search } = Input;

const AdminRoleFilter = memo(({ filterValue, updateFilterValue }: FilterProps<AdminRoleFilter>) => {
  const clear = () => {
    updateFilterValue({
      name: null,
      isDelete: null,
    });
  };

  const handleInputStringChange = (name: string, value: string) => {
    updateFilterValue({
      ...filterValue,
      [name]: value,
    });
  };

  const onChangeSelect = (name: string, value: string) => {
    updateFilterValue({
      ...filterValue,
      [name]: value,
    });
  };

  return (
    <div className="py-4">
      <div className="flex w-full">
        <div className="w-full">
          <div className="mb-4 flex items-center">
            <Search
              className="mr-4"
              placeholder="역할명"
              allowClear
              style={{ width: 180 }}
              value={filterValue?.name || ''}
              onChange={(e) => handleInputStringChange('name', e.target.value)}
            />
            <Select
              style={{ width: 100 }}
              placeholder="삭제여부"
              optionFilterProp="children"
              options={[
                { label: '삭제', value: true },
                { label: '미삭제', value: false },
              ]}
              value={filterValue.isDelete}
              onChange={(value) => onChangeSelect('isDelete', value)}
            />
          </div>
        </div>
        <div className="flex justify-end w-full mb-2">
          <Button onClick={clear}>초기화</Button>
        </div>
      </div>
    </div>
  );
});

export default AdminRoleFilter;
