import { getSalesPeople } from '@/api/public';
import SalesPeopleFilterSelector from '@/components/SalesPeople/SalesPeopleFilterSelector';
import SalesPeopleSearchResult from '@/components/SalesPeople/SalesPeopleSearchResult';
import { ADMIN_ROLE_FORBIDDEN } from '@/const/errorCode';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const SalesPeoplePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromQuery = parseInt(searchParams.get('page') || '1', 10);

  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedSalesPeopleFilterValue>({
    name: null,
    phoneNumber: null,
    safetyNumber: null,
    isDelete: null,
  });

  useEffect(() => {
    setSelectedFilterValue((prev) => ({
      ...prev,
      page: pageFromQuery,
    }));
  }, [searchParams]);

  const [currentPage, setCurrentPage] = useState(1);

  const getSalesPeopleData = async () => {
    const { name, phoneNumber, safetyNumber, isDelete } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      name: name || '',
      phoneNumber: phoneNumber || '',
      safetyNumber: safetyNumber || '',
      isDelete: isDelete ? String(isDelete) : '',
      page: String(currentPage ? currentPage : 1),
      size: '10',
    });
    const response = await getSalesPeople(queryParams);
    return response.data;
  };

  const {
    data: searchResultData,
    isLoading,
    isError,
    refetch,
  } = useQuery(['get-sales-people-data', selectedFilterValue], getSalesPeopleData, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    retry: (failureCount, error: any) => {
      if (error === ADMIN_ROLE_FORBIDDEN) {
        return false;
      }
      return failureCount < 3;
    },
  });

  useEffect(() => {
    setSelectedFilterValue((prev) => ({
      ...prev,
      page: pageFromQuery,
    }));
  }, [searchParams]);

  const handlePaginationChange = (page: number) => {
    if (page === 1) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
    } else {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: String(page) });
    }
  };

  return (
    <>
      <h2 className="hidden">영업 담당자 관리</h2>
      <h3 className="text-gray-8">
        영업 담당자 관리 / <span className="font-semibold">영업 담당자 조회</span>
      </h3>

      <SalesPeopleFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getData={() => refetch()}
      />

      <SalesPeopleSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getData={() => refetch()}
      />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default SalesPeoplePage;
