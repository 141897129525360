import { BASE_TONNAGE } from '@/const/license';
import { SPEED } from '@/const/vehicles';

export const formatShortYear = (year: string | number | undefined) => {
  if (year === undefined) {
    return '';
  }
  return String(year).slice(2);
};

export const formatNumberWithComma = (num: string | null | undefined, isInput?: boolean): string => {
  if (num === null || num === undefined || num === '') {
    return isInput ? '' : '0';
  } else {
    const numericPrice = Number(num);
    return numericPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export const getFormatDate = (
  date: string | undefined,
  format: string[] = ['year', 'month', 'day'],
  shortYear: boolean = false,
) => {
  if (date === undefined) {
    return '';
  }
  const [year, month, day] = date.split('-');

  const fullYear = shortYear ? formatShortYear(year) : year;

  const resultArray = format.map((element) => {
    switch (element) {
      case 'year':
        return `${fullYear}년`;
      case 'month':
        return `${month}월`;
      case 'day':
        return `${day}일`;
      default:
        return '';
    }
  });

  return resultArray.join(' ');
};

export const getOnlyDate = (dateTime: string | undefined) => {
  if (dateTime === undefined) {
    return '';
  }
  const [date, time] = dateTime.split(' ');
  return date;
};

// 이넘값을 select타입에 맞게 변환
export const convertToSelectOptions = (
  enumArray: EnumPresenter[],
  excludeCodes: string[] = [], // 기본값은 빈 배열
): { value: string; label: string }[] => {
  return enumArray
    ?.filter((item) => !excludeCodes.includes(item.code)) // excludeCodes 배열에 있는 코드를 제외
    .map((item) => ({ value: item?.code, label: item?.desc }));
};

// salesPeople 값을 select타입에 맞게 변환
export const convertToSalesPeopleOptions = (array: SalesPeopleType[]) => {
  return array?.map((item) => ({
    value: item?.id ? String(item?.id) : '',
    label: `${item?.name}(${item?.phoneNumber})`,
  }));
};

// partner 값을 select타입에 맞게 변환
export const convertToPartnerOptions = (array: PartnerType[]) => {
  return array?.map((item) => ({
    value: item?.id,
    label: item?.partnerName,
  }));
};

// adminRole 값을 Select 타입에 맞게 변환
export const convertToAdminRoleOptions = (array: AdminRoleFilterType[]) => {
  return array?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
};

// SPEED 매물 체크
export const isSpeed = (code: string | undefined | null) => {
  if (code === null) return;
  if (code === SPEED) {
    return true;
  } else {
    return false;
  }
};

// 주차 계산기

export const getMonthAndWeekFromWeekOfYear = (weekNumber: number) => {
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const year = new Date().getFullYear();

  // 윤년 조정
  function isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }
  if (isLeapYear(year)) {
    daysInMonth[1] = 29; // 2월을 29일로 설정
  }

  // 연도의 첫 목요일 계산
  const firstDayOfYear = new Date(year, 0, 1);
  const firstThursdayOffset = (4 - firstDayOfYear.getDay() + 7) % 7;
  const firstThursdayOfYear = new Date(year, 0, 1 + firstThursdayOffset);

  // 입력된 주차의 목요일 계산
  const targetThursday = new Date(firstThursdayOfYear);
  targetThursday.setDate(firstThursdayOfYear.getDate() + (weekNumber - 1) * 7);

  // 주차에 해당하는 월과 주차를 계산
  const targetMonth = targetThursday.getMonth(); // 주차가 속한 월
  const targetDate = targetThursday.getDate(); // 주차가 속한 일

  // 월의 첫 날과 타겟 목요일 날짜를 비교하여 주차 계산
  const monthStart = new Date(year, targetMonth, 1);
  const weekInMonth = Math.ceil((targetDate + monthStart.getDay()) / 7);

  return `${targetMonth + 1}월 ${weekInMonth}주차`;
};

export const getFileName = (url?: string) => {
  if (!url) {
    return '';
  }

  // URL에서 마지막 '/' 이후의 문자열을 추출
  const fileName = url.substring(url.lastIndexOf('/') + 1);
  return fileName;
};

// 입력값을 지정한 소수점 자리까지 포맷팅하는 함수
export const formatValueToDecimalPlaces = (value: string, decimalPlaces: number): string => {
  // 소수점과 숫자만 남기고 나머지 문자를 제거
  const cleanedValue = String(value).replace(/[^\d.]/g, '');

  // 소수점 이하 자리수 제어
  if (cleanedValue.includes('.')) {
    const parts = cleanedValue.split('.');
    // 소수점 이하 지정된 자리수까지만 유지
    return `${parts[0]}.${parts[1].slice(0, decimalPlaces)}`;
  } else {
    return cleanedValue;
  }
};

//
export const extractStatusFilter = (restoredParam: URLSearchParams, defaultValue: string[]) => {
  const rawStatus = restoredParam.getAll('productsStatus');

  // 쉼표로 구분된 경우 배열로 변환
  const processedStatus = rawStatus.length === 1 && rawStatus[0].includes(',') ? rawStatus[0].split(',') : rawStatus;

  // 값이 없으면 기본값 사용
  return processedStatus.filter(Boolean).length > 0 ? processedStatus : defaultValue;
};

export const determineLicensePlateDescription = (tons: number) => {
  let maxTons = BASE_TONNAGE;

  if (tons > BASE_TONNAGE) {
    maxTons = 25;
  } else if (tons * 1.5 > BASE_TONNAGE) {
    maxTons = Number((tons * 1.5).toFixed(1));
  }

  return maxTons;
};
