import React, { useEffect, useState } from 'react';

import { getJobList } from '@/api/public';
import FilterSelector from '@/components/Job/FilterSelector';
import SearchResult from '@/components/Job/SearchResult';
import { ADMIN_ROLE_FORBIDDEN } from '@/const/errorCode';
import { Pagination } from 'antd';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const JobList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromQuery = parseInt(searchParams.get('page') || '1', 10);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [selectedFilterValue, setSelectedFilterValue] = useState<JobSelectedFilterValue>({
    status: null,
    title: '',
    minTons: null,
    maxTons: null,
    salaryType: null,
    startDate: '',
    endDate: '',
  });

  const getJobData = async () => {
    const { status, title, minTons, maxTons, salaryType, startDate, endDate } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      status: status || '',
      title: title || '',
      minTons: minTons ? String(minTons) : '',
      maxTons: maxTons ? String(maxTons) : '',
      salaryType: salaryType || '',
      startDate: startDate || '',
      endDate: endDate || '',
      page: String(currentPage),
      size: '10',
    });

    const response = await getJobList(queryParams);
    return response.data;
  };

  const {
    data: searchResultData,
    isLoading,
    isError,
    refetch,
  } = useQuery(['get-job-data', selectedFilterValue, currentPage], getJobData, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    retry: (failureCount, error: any) => {
      if (error === ADMIN_ROLE_FORBIDDEN) {
        return false;
      }
      return failureCount < 3;
    },
  });

  const handlePaginationChange = (page: number) => {
    if (page === 1) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
    } else {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: String(page) });
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  useEffect(() => {
    setCurrentPage(pageFromQuery);
  }, [searchParams]);

  return (
    <>
      <h2 className="hidden">일자리 관리</h2>
      <h3 className="text-gray-8">
        운영 관리 / <span className="font-semibold">일자리 관리</span>
      </h3>

      <FilterSelector selectedFilterValue={selectedFilterValue} setSelectedFilterValue={setSelectedFilterValue} />
      <SearchResult currentPage={currentPage} data={searchResultData} getJobData={() => refetch()} />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default JobList;
