import React from 'react';
import { useNavigate } from 'react-router-dom';

interface FunctionActiveReportSearchResultProps {
  data: FunctionActiveReportResponse | null;
}

const FunctionActiveReportSearchResult = ({ data }: FunctionActiveReportSearchResultProps) => {
  const navigate = useNavigate();
  const navigateListPage = () => {
    navigate('/contracts', {
      state: {
        completedContractCount: data?.completedContractCount,
        notCompletedContractCount: data?.notCompletedContractCount,
      },
    });
  };
  return (
    <>
      <div className="px-4 py-2">
        <h1 className="mb-4 text-lg font-bold">기능활성화 지표</h1>
        <div className="flex justify-between gap-4 mb-4">
          <div className="flex-1 p-2 mr-1 border border-gray-300 rounded-lg" onClick={navigateListPage}>
            <h2 className="font-bold">전자 계약서</h2>
            <p>
              신규체결 {data?.newContractCount} 체결완료 {data?.completedContractCount} 진행중{' '}
              {data?.notCompletedContractCount}
            </p>
          </div>
          <div className="flex-1 p-2 border border-gray-300 rounded-lg">
            <h2 className="font-bold">운행 일지</h2>
            <p>
              신규차량 등록수 {data?.newDriveVehicleInfoRegisterCount} 총 차량등록자수{' '}
              {data?.totalDriveVehicleInfoRegisterCount} 최초 이용자 {data?.firstUseMemberCount} 적극 이용자{' '}
              {data?.positivelyUseMemberCount}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FunctionActiveReportSearchResult;
