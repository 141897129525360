import { getLeaseServices } from '@/api/services/getServices';
import NewCarLeaseList from '@/components/NewCarLeaseServices/NewCarLeaseList';
import NewCarLeaseServicesFilter from '@/components/NewCarLeaseServices/NewCarLeaseServicesFilter';
import { ADMIN_ROLE_FORBIDDEN } from '@/const/errorCode';
import { Pagination } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const NewCarLeaseServices = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromQuery = parseInt(searchParams.get('page') || '1', 10);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedFilterValue, setSelectedFilterValue] = useState<LeaseServiceFilter>({
    name: null,
    phoneNumber: null,
    status: null,
    startDate: null,
    endDate: null,
  });

  const getLeaseServicesData = useCallback(async () => {
    const { name, phoneNumber, status, startDate, endDate } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      name: name || '',
      phoneNumber: phoneNumber || '',
      status: status || '',
      startDate: startDate || '',
      endDate: endDate || '',
      page: String(currentPage ? currentPage : 1),
      size: '10',
    });
    const response = await getLeaseServices(queryParams);
    return response;
  }, [selectedFilterValue, currentPage]);

  const { data: searchResultData, refetch } = useQuery(
    ['get-lease-services', selectedFilterValue, currentPage],
    () => getLeaseServicesData(),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 5,
      retry: (failureCount, error: any) => {
        if (error === ADMIN_ROLE_FORBIDDEN) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );

  const handlePaginationChange = useCallback(
    (page: number) => {
      setSearchParams((prev) => {
        const newSearchParams = new URLSearchParams(prev);
        if (page === 1) {
          newSearchParams.delete('page');
        } else {
          newSearchParams.set('page', String(page));
        }
        return newSearchParams;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    setCurrentPage((prev) => (pageFromQuery !== prev ? pageFromQuery : prev));
  }, [pageFromQuery]);

  const updateSelectedFilterValue = useCallback((data: LeaseServiceFilter) => {
    setSelectedFilterValue(data);
  }, []);

  return (
    <>
      <h2 className="hidden">신차리스 관리</h2>
      <h3 className="text-gray-8">
        부가서비스 관리 / <span className="font-semibold">신차리스</span>
      </h3>
      <NewCarLeaseServicesFilter filterValue={selectedFilterValue} updateFilterValue={updateSelectedFilterValue} />
      <NewCarLeaseList
        data={searchResultData}
        currentPage={currentPage}
        getData={() => refetch()}
        filterValue={selectedFilterValue}
      />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default NewCarLeaseServices;
