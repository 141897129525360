import { patchNotification, postNotification } from '@/api/public';
import { NO_REQUIRED_VALUE_ERROR_MESSAGE } from '@/const/errorMessage';
import { notificationEnumAtom } from '@/store/notification';
import { convertToSelectOptions } from '@/utils/common';
import { Button, Col, DatePicker, DatePickerProps, Divider, Input, Row, Select, message } from 'antd';
import ko from 'antd/es/date-picker/locale/ko_KR';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const NotificationReservationForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const data = location.state?.notificationData || {};

  useEffect(() => {
    if (id && data) {
      setSelectedValue(data);
    }
  }, [id]);

  const postNotificationReservationMutation = useMutation(
    (requestData: SelectedNotificationValue) => postNotification(requestData),
    {
      onSuccess: () => {
        message.success('예약알림이 등록되었습니다.', 2);
        queryClient.invalidateQueries({ queryKey: ['get-notification-data'] });
        onClickGoToList();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const onClickRegister = () => {
    const { notificationType, reservationDate, title, contents, redirectUrl } = selectedValue;

    if (notificationType === null || reservationDate === '' || title === '' || contents === '') {
      message.error(NO_REQUIRED_VALUE_ERROR_MESSAGE, 2);
    } else {
      const request = {
        notificationType,
        reservationDate: reservationDate ? reservationDate.concat(':00') : '',
        title,
        contents,
        redirectUrl,
      };

      postNotificationReservationMutation.mutate(request);
    }
  };
  const labelStyle: React.CSSProperties = { padding: '8px 0', fontWeight: '700' };
  const style: React.CSSProperties = { padding: '8px 0' };

  const [notificationFilterInfo, setNotificationFilterInfo] = useAtom(notificationEnumAtom);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);

  const convertNotificationFilterInfoToSelectOptions = (notificationFilterInfo: NotificationFilterInfoType) => {
    return {
      notificationType: convertToSelectOptions(notificationFilterInfo.notificationType),
    };
  };

  const [selectedValue, setSelectedValue] = useState<SelectedNotificationValue>({
    notificationType: null,
    reservationDate: '',
    title: '',
    contents: '',
    redirectUrl: '',
  });

  const onChange: DatePickerProps['onChange'] = (_, dateStr) => {
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      reservationDate: dateStr as string,
    }));
  };

  const onChangeInput = (name: string, value: string | boolean) => {
    setIsDisabledEditBtn(false);
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const selectOptions = convertNotificationFilterInfoToSelectOptions(notificationFilterInfo);

  const onChangeSelect = (name: string, value: string | boolean) => {
    setIsDisabledEditBtn(false);
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const onClickGoToList = () => {
    navigate('/notification');
  };

  const patchNotificationMutation = useMutation((requestData) => patchNotification(String(id), requestData), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get-notification-data'] });
      navigate(-1);
    },
    onError: () => {},
  });

  const onClickUpdate = () => {
    const request: any = {};

    if (data?.title !== selectedValue.title) {
      request.title = selectedValue.title;
    }

    if (data?.contents !== selectedValue.contents) {
      request.contents = selectedValue.contents;
    }

    if (data?.notificationType.code !== selectedValue?.notificationType?.code) {
      request.notificationType = selectedValue.notificationType;
    }

    if (data?.reservationDate !== selectedValue.reservationDate) {
      request.reservationDate = selectedValue.reservationDate;
    }

    if (data?.redirectUrl !== selectedValue.redirectUrl) {
      request.redirectUrl = selectedValue.redirectUrl;
    }
    patchNotificationMutation.mutate(request);
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            알림 타입
            <span className="ml-1 font-normal text-red-500">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style}>
            <Select
              className="my-2 mr-4"
              style={{ width: 200 }}
              placeholder="알림 타입 선택"
              optionFilterProp="children"
              options={selectOptions.notificationType}
              value={selectedValue?.notificationType?.desc}
              onChange={(value) => onChangeSelect('notificationType', value)}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            예약 일시<span className="ml-1 font-normal text-red-500">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style}>
            <DatePicker
              className="my-2 mr-4"
              style={{ width: 200 }}
              showTime={{ format: 'HH:mm', minuteStep: 30 }}
              locale={ko}
              onChange={onChange}
              format="YYYY-MM-DD HH:mm"
              value={selectedValue.reservationDate ? dayjs(selectedValue.reservationDate) : null}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            제목<span className="ml-1 font-normal text-red-500">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={20}>
          <div style={style}>
            <TextArea
              value={selectedValue.title || ''}
              onChange={(e) => onChangeInput('title', e.target.value)}
              placeholder="알림 제목 입력"
              autoSize={{ minRows: 8, maxRows: 8 }}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            내용<span className="ml-1 font-normal text-red-500">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={20}>
          <div style={style}>
            <TextArea
              value={selectedValue.contents || ''}
              onChange={(e) => onChangeInput('contents', e.target.value)}
              placeholder="알림 내용 입력"
              autoSize={{ minRows: 8, maxRows: 8 }}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>리다이렉트 url</div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div style={style}>
            <Input
              style={{ width: '100%' }}
              value={selectedValue.redirectUrl || ''}
              onChange={(e) => onChangeInput('redirectUrl', e.target.value)}
              placeholder="리다이렉트 주소"
            />
          </div>
        </Col>
      </Row>

      <Divider orientation="left"></Divider>

      {id ? (
        <div className="flex justify-end">
          <Button onClick={onClickGoToList}>취소</Button>
          <Button type="primary" className="bg-[#1890ff] mx-2 w-20" onClick={onClickUpdate}>
            수정하기
          </Button>
        </div>
      ) : (
        <div className="flex justify-end">
          <Button onClick={onClickGoToList}>취소</Button>
          <Button type="primary" className="bg-[#1890ff] mx-2 w-20" onClick={onClickRegister}>
            등록하기
          </Button>
        </div>
      )}
    </>
  );
};

export default NotificationReservationForm;
