import apiManager from '../ApiManager';

export const patchLeaseServices = async (leaseServiceId: number, requestBody: any) => {
  const res = await apiManager.patch(`/admin/v1/lease-services/${leaseServiceId}`, requestBody);
  return res.data;
};

export const patchAdminMembers = async (adminMemberId: number, requestBody: any) => {
  const res = await apiManager.patch(`/admin/v1/admin-members/${adminMemberId}`, requestBody);
  return res.data;
};
