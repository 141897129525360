// SalesType
export const ALL = 'ALL'; // 전체
export const NORMAL = 'NORMAL'; // 직거래
export const ASSURANCE = 'ASSURANCE'; // 직트럭 상품용
export const CONSIGNMENT = 'CONSIGNMENT'; // 판매 대행

// Theunban 차량 구분 목적
export const THEUNBAN = 'THEUNBAN';

// ProductsType
export const SPEED = 'SPEED'; // 매입견적
export const DIRECT = 'DIRECT'; // 직거래

export const DEFAULT_FILTER_STATUSES = ['SALE', 'PAUSE', 'COMPLETED'];
