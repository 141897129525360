import { Table, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react';

interface CallingHistoryDataProps {
  selectedFilterValue: SelectedCallingHistoryFilterValue;
  data: CallingHistoryResponse | null;
  currentPage: number;
  getCallingHistoryData: () => void;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  calledDate: string;
  callingNumber: string;
  safetyNumber: string;
  calledNumber: string;
  callDuration: number;
  product: ProductDetail;
  salesPeople: SalesPeople;
  member: Member;
}
const CallingHistorySearchResult = ({
  selectedFilterValue,
  data,
  currentPage,
  getCallingHistoryData,
}: CallingHistoryDataProps) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '발신자',
      dataIndex: 'member',
      render: (text, record) => record.member.name,
    },
    {
      title: '발신자 전화번호',
      dataIndex: 'callingNumber',
    },
    {
      title: '수신자',
      dataIndex: 'salesPeople',
      render: (text, record) => record.salesPeople.name,
    },
    {
      title: '수신자 전화번호',
      dataIndex: 'calledNumber',
    },
    {
      title: '안심번호',
      dataIndex: 'safetyNumber',
    },
    {
      title: '통화요청일시',
      dataIndex: 'calledDate',
    },
    {
      title: '통화시간(초)',
      dataIndex: 'callDuration',
    },
    {
      title: '차량명',
      dataIndex: 'product',
      render: (text, record) =>
        record.product ? record.product.truckName + '(' + record.product.productsNumber + ')' : '-',
    },
  ];
  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      member: item.member,
      salesPeople: item.salesPeople,
      product: item.product,
      calledNumber: item.calledNumber,
      callingNumber: item.callingNumber,
      safetyNumber: item.safetyNumber,
      calledDate: item.calledDate,
      callDuration: item.callDuration || 0,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div></div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
    </>
  );
};

export default CallingHistorySearchResult;
