import { leaseServicesEnumAtom } from '@/store/leaseServices';
import { convertToSelectOptions } from '@/utils/common';
import { Button, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React, { memo } from 'react';

const { Search } = Input;
const { RangePicker } = DatePicker;

const NewCarLeaseServicesFilter = memo(({ filterValue, updateFilterValue }: FilterProps<LeaseServiceFilter>) => {
  const [leaseServicesFilterInfo] = useAtom(leaseServicesEnumAtom);
  const convertLeaseServicesFilterInfoToSelectOptions = (leaseServicesFilterInfo: LeaseServicesFilterInfoType) => {
    return {
      status: convertToSelectOptions(leaseServicesFilterInfo?.status),
    };
  };
  const selectOptions = convertLeaseServicesFilterInfoToSelectOptions(leaseServicesFilterInfo);

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    updateFilterValue({
      ...filterValue,
      startDate,
      endDate,
    });
  };

  const clear = () => {
    updateFilterValue({
      name: null,
      phoneNumber: null,
      status: null,
      startDate: null,
      endDate: null,
    });
  };

  const handleInputStringChange = (name: string, value: string) => {
    updateFilterValue({
      ...filterValue,
      [name]: value,
    });
  };

  const onChangeSelect = (name: string, value: string) => {
    updateFilterValue({
      ...filterValue,
      [name]: value,
    });
  };

  return (
    <div className="py-4">
      <div className="flex w-full">
        <div className="w-full">
          <div className="flex items-center mb-4">
            <Search
              className="mr-4"
              placeholder="신청자명"
              allowClear
              style={{ width: 180 }}
              value={filterValue?.name || ''}
              onChange={(e) => handleInputStringChange('name', e.target.value)}
            />

            <Search
              className="mr-4"
              placeholder="전화번호 입력"
              allowClear
              style={{ width: 180 }}
              value={filterValue?.phoneNumber || ''}
              onChange={(e) => handleInputStringChange('phoneNumber', e.target.value)}
            />

            <Select
              className="my-2 mr-4"
              style={{ width: 200 }}
              placeholder="상태"
              optionFilterProp="children"
              options={selectOptions.status}
              value={filterValue.status}
              onChange={(value) => onChangeSelect('status', value)}
            />
          </div>
          <span className="mr-2">신청일자</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              filterValue.startDate ? dayjs(filterValue.startDate) : null,
              filterValue.endDate ? dayjs(filterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 일자', '종료 일자']}
          />
        </div>
        <div className="flex justify-end w-full my-2">
          <Button onClick={clear}>초기화</Button>
        </div>
      </div>
    </div>
  );
});

export default NewCarLeaseServicesFilter;
