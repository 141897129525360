import { deleteSalesPeople, patchSalesPeople, postSalesPeople } from '@/api/public';
import { validatePhoneNumber } from '@/utils/validation';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Input, Modal, Table, TableColumnsType, message } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import BasicPopup from '../Common/BasicPopup';

interface SalesPeopleResponseDataProps {
  selectedFilterValue: SelectedSalesPeopleFilterValue;
  data: PaginatedResponse<SalesPeople> | null;
  currentPage: number;
  getData: () => void;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  name?: string;
  phoneNumber?: string;
  safetyNumber?: string;
  isDelete: boolean;
  createdDate: string;
}

const SalesPeopleSearchResult = ({ data, currentPage, getData }: SalesPeopleResponseDataProps) => {
  const columns: TableColumnsType<DataType> = [
    { title: '번호', dataIndex: 'index' },
    { title: '이름', dataIndex: 'name' },
    { title: '전화번호', dataIndex: 'phoneNumber' },
    { title: '안심번호', dataIndex: 'safetyNumber' },
    { title: '가입일시', dataIndex: 'createdDate' },
    {
      title: '삭제여부',
      dataIndex: 'isDelete',
      render: (text, record) => (record.isDelete == true ? '삭제' : '미삭제'),
    },
  ];

  const [nameInput, setNameInput] = useState('');
  const [phoneNumberInput, setPhoneNumberInput] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSalesPeopleId, setSelectedSalesPeopleId] = useState(0);
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const rowProps = (record: any) => ({
    onClick: () => {
      setIsEdit(true);
      setIsOpenModal(true);
      setSelectedSalesPeopleId(record.id);
      setNameInput(record.name);
      setPhoneNumberInput(record.phoneNumber);
    },
  });

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      name: item.name || '-',
      phoneNumber: item.phoneNumber || '-',
      safetyNumber: item.safetyNumber || '-',
      createdDate: item.createdDate || '-',
      isDelete: !!item.isDelete,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const postSalesPeopleMutation = useMutation(
    (requestData: { name: string; phoneNumber: string }) => postSalesPeople(requestData),
    {
      onSuccess: () => {
        message.success('영업 담당자가 등록되었습니다.', 2);

        getData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  const handleCloseModal = useCallback(() => {
    setIsOpenModal(false);
    setNameInput('');
    setPhoneNumberInput('');
    setIsEdit(false);
  }, []);

  const handleSave = () => {
    handleCloseModal();
    const request = {
      name: nameInput,
      phoneNumber: String(phoneNumberInput),
    };
    if (isEdit) {
      patchSalesPeopleMutation.mutate(request);
    } else {
      postSalesPeopleMutation.mutate(request);
    }
  };

  const patchSalesPeopleMutation = useMutation(
    (requestData: { name: string; phoneNumber: string }) =>
      patchSalesPeople(String(selectedSalesPeopleId), requestData),
    {
      onSuccess: () => {
        message.success('영업 담당자 정보가 변경되었습니다.', 2);
        setIsInputChanged(false);
        getData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const onClickDelete = () => {
    setIsOpenPopup(false);

    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      deleteSalesPeopleMutation.mutate(String(firstSelectedId));
      getData();
    } else {
      message.error('영업 담당자가 선택되지 않았어요. 삭제할 영업 담당자를 선택해주세요.');
    }
  };

  const deleteSalesPeopleMutation = useMutation(deleteSalesPeople, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      setSelectedRowKeys([]);
      setIsInputChanged(false);
      getData();
    },
    onError: (error: AxiosError) => {
      if (error.code === 'SALES_PEOPLE_HAS_PRODUCTS') {
        message.error(error.message, 2);
      } else {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      }
    },
  });

  const isDisabledBtn = () => {
    return !isInputChanged || !nameInput || !phoneNumberInput || !validatePhoneNumber(phoneNumberInput).isValid;
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 영업 담당자수</span> {data?.totalElements || 0}명
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}명 선택됨` : ''}</span>
          </div>
          <div>
            <Button
              className="mr-2"
              style={{ borderColor: '#1890ff', color: '#1890ff' }}
              onClick={() => setIsOpenModal(true)}
            >
              등록
            </Button>
            <Button danger onClick={() => setIsOpenPopup(true)}>
              삭제
            </Button>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          onRow={rowProps}
        />
      </div>

      <Modal
        title={<div style={{ textAlign: 'center', paddingBottom: '16px' }}>영업 담당자 {isEdit ? '수정' : '등록'}</div>}
        open={isOpenModal}
        onCancel={handleCloseModal}
        centered
        footer={[
          <Button key="close" onClick={handleCloseModal}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleSave}
            disabled={isDisabledBtn()}
          >
            확인
          </Button>,
        ]}
      >
        <div className="flex items-center justify-center mb-4">
          <span className="w-16">이름:</span>
          <Input
            style={{ width: 200 }}
            placeholder="영업 담당자 이름"
            value={nameInput}
            onChange={(e) => {
              setIsInputChanged(true);
              setNameInput(e.target.value);
            }}
          />
        </div>

        <div className={`flex items-center justify-center`}>
          <span className="w-16">전화번호:</span>

          <Input
            style={{ width: 200 }}
            placeholder="영업 담당자 전화번호"
            value={String(phoneNumberInput)}
            onChange={(e) => {
              setIsInputChanged(true);
              setPhoneNumberInput(e.target.value);
            }}
            status={!!phoneNumberInput && !validatePhoneNumber(phoneNumberInput).isValid ? 'error' : ''}
          />
        </div>
        <div className="flex items-center justify-center h-[20px]">
          <span className="w-16"></span>
          <div className="text-[12px] text-red-500 w-[200px] ml-2">
            {!!phoneNumberInput && validatePhoneNumber(phoneNumberInput).errorMessage}
          </div>
        </div>
      </Modal>

      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="정말 삭제하시겠어요?"
        content=""
        handleOk={() => onClickDelete()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>
    </>
  );
};

export default SalesPeopleSearchResult;
