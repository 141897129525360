import { phoneNumberRegex } from '@/const/regex';

export interface Validation {
  isValid: boolean;
  errorMessage: string;
}

export const validatePhoneNumber = (number: string | null | undefined) => {
  if (number === null || number === undefined) {
    return { isValid: false, errorMessage: '' } as Validation;
  }
  if (!phoneNumberRegex.test(number)) {
    return { isValid: false, errorMessage: '올바른 휴대폰 번호가 아닙니다.' } as Validation;
  }
  return { isValid: phoneNumberRegex.test(number) } as Validation;
};
