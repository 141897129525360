import { patchCapitalCounselServices } from '@/api/public';
import { capitalCounselServicesEnumAtom } from '@/store/capitalCounselServices';
import { productsEnumAtom } from '@/store/products';
import { convertToSalesPeopleOptions, convertToSelectOptions } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import BasicPopup from '../Common/BasicPopup';

interface CapitalCounselServicesResponseDataProps {
  data: CapitalCounselServicesResponse | null;
  currentPage: number;
  getCapitalCounselServicesData: () => void;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  name: string;
  phoneNumber: string;
  counselStatus: EnumPresenter;
  productId?: number;
  productNumber?: number;
  price?: number;
  createdDate?: string;
  comment?: string;
  salesPeople?: SalesPeople;
}

const CapitalCounselServicesSearchResult = ({
  data,
  currentPage,
  getCapitalCounselServicesData,
}: CapitalCounselServicesResponseDataProps) => {
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [id, setId] = useState<string>('');
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [productFilterEnum] = useAtom(productsEnumAtom);
  const [capitalCounselServicesFilterInfo] = useAtom(capitalCounselServicesEnumAtom);
  const [basicPopupData, setBasicPopupData] = useState({
    title: '',
    handleOk: () => {},
    handleCancel: () => {},
  });

  const convertCapitalCounselServicesFilterInfoSelectOptions = (
    productFilterEnum: ProductsFilterInfoType,
    capitalCounselServicesFilterInfo: CapitalCounselServicesFilterInfoType,
  ) => {
    return {
      counselStatus: convertToSelectOptions(capitalCounselServicesFilterInfo.counselStatus),
      salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
    };
  };

  const selectOptions = convertCapitalCounselServicesFilterInfoSelectOptions(
    productFilterEnum,
    capitalCounselServicesFilterInfo,
  );

  const handleCounselStatusChange = (value: string, record: DataType) => {
    const request = {
      id: String(record.id),
      counselStatus: value,
    };
    setBasicPopupData({
      title: '상태를 변경하시겠어요?',
      handleOk: () => onClickCounselStatusChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenPopup(true);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '신청자',
      dataIndex: 'name',
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: '상태',
      dataIndex: 'counselStatus',
      render: (text, record) => (
        <Select
          value={record.counselStatus?.desc}
          options={selectOptions.counselStatus}
          style={{ width: 90 }}
          onChange={(value) => handleCounselStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: '제시번호',
      dataIndex: 'productNumber',
    },
    {
      title: '거래금액',
      dataIndex: 'price',
      render: (text, record) => <>{record.price}만원</>,
    },
    {
      title: '요청일자',
      dataIndex: 'createdDate',
    },
    {
      title: '담당자',
      dataIndex: 'salesPeople',
      render: (text, record) => (
        <Select
          value={record.salesPeople?.name}
          options={selectOptions.salesPeople}
          style={{ width: 100 }}
          onChange={(value) => handleSalesPeopleChange(Number(value), record)}
          onClick={(e) => e.stopPropagation()}
        ></Select>
      ),
    },
    {
      title: '메모',
      dataIndex: 'comment',
      render: (text, record) => (
        <>
          {record.comment ? (
            <>
              <p
                className="mr-3 overflow-hidden font-bold break-all whitespace-no-wrap text-ellipsis"
                style={{
                  maxWidth: '500px',
                  maxHeight: '200px',
                }}
              >
                {record.comment}
              </p>

              <Button onClick={() => handleCommentButtonClick(record)}>수정</Button>
            </>
          ) : (
            <Button onClick={() => handleCommentButtonClick(record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const handleCommentButtonClick = (capitalCounselServices: any) => {
    setIsCommentModalVisible(true);
    setId(capitalCounselServices.id);
    setComment(capitalCounselServices.comment);
  };

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      name: item.name,
      phoneNumber: item.phoneNumber,
      counselStatus: item.counselStatus || null,
      productId: item.productId,
      productNumber: item.productNumber,
      price: item.price,
      createdDate: item.createdDate || '-',
      comment: item.comment || '',
      salesPeople: item.salesPeople,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onClickCounselStatusChangeButton = (requestData: { id: string; counselStatus: string }) => {
    patchCapitalCounselServicesCounselStatusModifyMutation.mutate(requestData);
  };

  const onClickCancelButton = () => {
    setIsOpenPopup(false);
  };

  const patchCapitalCounselServicesCounselStatusModifyMutation = useMutation(
    (requestData: { id: string; comment?: string; counselStatus?: string }) =>
      patchCapitalCounselServices(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('상태가 변경되었습니다.', 2);
        getCapitalCounselServicesData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  const patchCapitalCounselServicesCommentModifyMutation = useMutation(
    (requestData: { id: string; comment?: string }) => patchCapitalCounselServices(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('메모가 변경되었습니다.', 2);
        getCapitalCounselServicesData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  const onChangeComment = (comment: string) => {
    setComment(comment);
  };

  const handleCommentModalClose = () => {
    setIsCommentModalVisible(false);
    setComment('');
  };

  const handleCommentSave = () => {
    handleCommentModalClose();
    const request = {
      id: id,
      comment: comment,
    };
    patchCapitalCounselServicesCommentModifyMutation.mutate(request);
  };

  const handleSalesPeopleChange = (value: number, record: DataType) => {
    const request = {
      id: String(record.id),
      salesPeopleId: value,
    };
    setBasicPopupData({
      title: '담당자를 변경하시겠어요?',
      handleOk: () => onClickSalesPeopleChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenPopup(true);
  };

  const onClickSalesPeopleChangeButton = (requestData: { id: string; salesPeopleId: number }) => {
    patchCapitalCounselServicesSalesPeopleMutation.mutate(requestData);
  };

  const patchCapitalCounselServicesSalesPeopleMutation = useMutation(
    (requestData: { id: string; salesPeopleId: number }) => patchCapitalCounselServices(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('담당자가 변경되었습니다.', 2);
        getCapitalCounselServicesData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 신청건수</span> {data?.totalElements}개
          </div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={() => basicPopupData.handleOk()}
        handleCancel={() => basicPopupData.handleCancel()}
      ></BasicPopup>

      <Modal
        title="메모 보기"
        open={isCommentModalVisible}
        onCancel={handleCommentModalClose}
        footer={[
          <Button key="close" onClick={handleCommentModalClose}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleCommentSave}
            disabled={!!!comment}
          >
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={comment}
          onChange={(e) => onChangeComment(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
    </>
  );
};

export default CapitalCounselServicesSearchResult;
