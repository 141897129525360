import { getSubsidyForFuelStandard } from '@/api/public';
import SubsidyForFuelStandardSearchResult from '@/components/SubsidyForFuelStandard/SubsidyForFuelStandardSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const SubsidyForFuelStandardList = () => {
  const [searchResultData, setSearchResultData] = useState<SubsidyForFuelStandardResponse | null>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const getSubsidyForFuelStandardData = async (page?: number) => {
    try {
      const queryParams = new URLSearchParams({
        page: String(page ? page : 1),
        size: '10',
      });

      const response = await getSubsidyForFuelStandard(queryParams);
      setSearchResultData(response.data);
    } catch (error: any) {
      console.log(error);
      setSearchResultData(null);
    }
  };

  useEffect(() => {
    getSubsidyForFuelStandardData(currentPage);
  }, [currentPage]);

  return (
    <>
      <h2 className="hidden">유류세 연동 보조금 페이지 - 유류세 연동 보조금 관리</h2>
      <h3 className="text-gray-8">
        관리자 및 데이터 관리 / <span className="font-semibold">유류세 연동 보조금 관리</span>
      </h3>
      <SubsidyForFuelStandardSearchResult
        data={searchResultData}
        getSubsidyForFuelStandardData={() => getSubsidyForFuelStandardData(currentPage)}
      ></SubsidyForFuelStandardSearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};
export default SubsidyForFuelStandardList;
