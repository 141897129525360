import { patchLeaseServices } from '@/api/services/patchServices';
import { postLeaseServicesResend } from '@/api/services/postServices';
import { oneStopServicesEnumAtom } from '@/store/oneStopServices';
import { productsEnumAtom } from '@/store/products';
import { convertToSalesPeopleOptions, convertToSelectOptions, formatNumberWithComma } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import BasicPopup from '../Common/BasicPopup';
import ExcelDownloadButton from '../Common/Button/ExcelDownloadButton';

interface LeaseServiceDataType {
  id: number;
  key: number;
  index: number;
  name: string;
  phoneNumber: string;
  truckName: string;
  monthlyLeaseFee: number | string;
  isSend: boolean;
  sendCount: number;
  successCount: number;
  createdDate: string;
  comment: string;
  status: EnumPresenter;
}

const NewCarLeaseList = memo(
  ({ data, currentPage, getData, filterValue }: SearchResultListProps<LeaseService, LeaseServiceFilter>) => {
    const [isMemoModalVisible, setIsMemoModalVisible] = useState(false);
    const [selectedMemoId, setSelectedMemoId] = useState(0);
    const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);
    const [memoContent, setMemoContent] = useState('');
    const [oneStopServicesFilterInfo] = useAtom(oneStopServicesEnumAtom);
    const [productFilterEnum] = useAtom(productsEnumAtom);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [basicPopupData, setBasicPopupData] = useState({
      title: '',
      handleOk: () => {},
      handleCancel: () => {},
    });

    const convertOneStopServicesFilterInfoToSelectOptions = (
      productFilterEnum: ProductsFilterInfoType,
      oneStopServicesFilterInfo: OneStopServicesFilterInfoType,
    ) => {
      return {
        status: convertToSelectOptions(oneStopServicesFilterInfo.status),
        salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
      };
    };

    const selectOptions = convertOneStopServicesFilterInfoToSelectOptions(productFilterEnum, oneStopServicesFilterInfo);

    const columns: TableColumnsType<LeaseServiceDataType> = [
      { title: '번호', dataIndex: 'index' },
      { title: '이름', dataIndex: 'name' },
      { title: '전화번호', dataIndex: 'phoneNumber' },
      { title: '차량명', dataIndex: 'truckName' },
      { title: '리스료', dataIndex: 'monthlyLeaseFee', render: (text) => <>{formatNumberWithComma(text)}</> },
      { title: '전송여부', dataIndex: 'isSend', render: (text, record) => <>{record.isSend ? '전송' : '실패'}</> },
      { title: '신청일자', dataIndex: 'createdDate' },
      {
        title: '발송/성공',
        dataIndex: 'sendSuccessCount',
        render: (text, record) => (
          <>
            {record.sendCount} / {record.successCount}
          </>
        ),
      },
      {
        title: '재발송',
        dataIndex: 'isSend',
        render: (text, record) => (
          <>
            <Button onClick={(e) => handleClickResend(e, record)}>재발송</Button>
          </>
        ),
      },
      {
        title: '상태',
        dataIndex: 'status',
        render: (text, record) => (
          <Select
            value={record.status?.desc}
            options={selectOptions.status}
            style={{ width: 90 }}
            onChange={(value) => handleStatusChange(value, record)}
          ></Select>
        ),
      },
      {
        title: '메모관리',
        dataIndex: 'comment',
        render: (text, record) => (
          <>
            {record?.comment ? (
              <>
                <p
                  className="mr-3 overflow-hidden font-bold break-all whitespace-no-wrap text-ellipsis"
                  style={{
                    maxWidth: '500px',
                    maxHeight: '200px',
                  }}
                >
                  {record?.comment}
                </p>

                <Button onClick={() => handleClickMemo(record)}>수정</Button>
              </>
            ) : (
              <Button onClick={() => handleClickMemo(record)}>작성</Button>
            )}
          </>
        ),
      },
    ];

    const handleStatusChange = (value: string, record: LeaseServiceDataType) => {
      const request = {
        id: record.id,
        status: value,
      };
      setBasicPopupData({
        title: '상태를 변경하시겠어요?',
        handleOk: () => onClickStatusChangeButton(request),
        handleCancel: () => onClickCancelButton(),
      });
      setIsOpenPopup(true);
    };

    const onClickStatusChangeButton = (requestData: { id: number; status: string }) => {
      patchLeaseServicesCompletedMutation.mutate(requestData);
    };

    const onClickCancelButton = () => {
      setIsOpenPopup(false);
    };

    const postLeaseServicesResendMutation = useMutation(
      (requestData: LeaseServiceResendRequest) => postLeaseServicesResend(requestData),
      {
        onSuccess: () => {
          message.success('재발송되었습니다.', 2);
          getData();
        },
        onError: (error: AxiosError) => {
          if (error.message) {
            message.error(error.message, 2);
          } else {
            console.log(error);
          }
        },
      },
    );

    const handleClickResend = useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>, data: LeaseServiceDataType) => {
        const requestData = {
          appliedAt: data.createdDate,
          id: data.id,
          memberName: data.name,
          memberPhoneNumber: data.phoneNumber,
          monthlyLeaseFee: data.monthlyLeaseFee,
          truckName: data.truckName,
        };
        postLeaseServicesResendMutation.mutate(requestData);
      },
      [postLeaseServicesResendMutation],
    );

    const handleClickMemo = useCallback((data: LeaseServiceDataType) => {
      setIsMemoModalVisible(true);
      setSelectedMemoId(data.id);
      setIsDisabledEditBtn(true);
      setMemoContent(data.comment);
    }, []);

    const tableData = useMemo(() => {
      return (
        data?.data?.map((item, index) => ({
          id: item.id,
          key: item.id,
          index: (currentPage - 1) * 10 + index + 1,
          name: item.member.name || '-',
          phoneNumber: item.member.phoneNumber || '-',
          truckName: item.leaseTruck.truckName || '-',
          monthlyLeaseFee: item.leaseTruck.monthlyLeaseFee || '-',
          isSend: item.isSend || false,
          sendCount: item.sendCount || 0,
          successCount: item.successCount || 0,
          createdDate: item.createdDate || '-',
          comment: item.comment || '',
          status: item.status || null,
        })) || []
      );
    }, [data, currentPage]);

    const handleMemoModalClose = () => {
      setIsMemoModalVisible(false);
      setMemoContent('');
    };

    const handleMemoSave = () => {
      handleMemoModalClose();
      updateLeaseCommentMutation.mutate({ comment: memoContent });
    };

    const updateLeaseCommentMutation = useMutation(
      (requestData: { comment: string }) => patchLeaseServices(selectedMemoId, requestData),
      {
        onSuccess: () => {
          message.success('메모가 수정되었습니다.', 2);
          setIsDisabledEditBtn(true);
          getData();
        },
        onError: (error: AxiosError) => {
          if (error.message) {
            message.error(error.message, 2);
          } else {
            console.log(error);
          }
        },
      },
    );

    const patchLeaseServicesCompletedMutation = useMutation(
      (requestData: { id: number; status: string }) =>
        patchLeaseServices(requestData.id, { status: requestData.status }),
      {
        onSuccess: () => {
          message.success('상태가 변경되었습니다.', 2);
          getData();
        },
        onError: (error: AxiosError) => {
          if (error.message) {
            message.error(error.message, 2);
          } else {
            console.log(error);
          }
        },
        onSettled: () => {
          setIsOpenPopup(false);
        },
      },
    );

    const onChangeMemo = (memo: string) => {
      setMemoContent(memo);
      setIsDisabledEditBtn(false);
    };

    const excelDownloadParams = useMemo(() => {
      return {
        name: filterValue?.name || '',
        phoneNumber: filterValue?.phoneNumber || '',
        status: filterValue?.status || '',
        startDate: filterValue?.startDate || '',
        endDate: filterValue?.endDate || '',
      };
    }, [filterValue]);

    return (
      <>
        <div className="py-4">
          <div className="flex justify-between" style={{ marginBottom: 16 }}>
            <div>
              <span className="font-bold">전체 신청건수</span> {data?.totalElements}개
            </div>
            <div>
              <ExcelDownloadButton
                apiPath="/admin/v1/lease-services/excel"
                params={excelDownloadParams}
                buttonText="엑셀 다운로드"
              />
            </div>
          </div>
          <Table columns={columns} dataSource={tableData} pagination={false} />
        </div>

        <Modal
          title="메모 보기"
          open={isMemoModalVisible}
          onCancel={handleMemoModalClose}
          footer={[
            <Button key="close" onClick={handleMemoModalClose}>
              닫기
            </Button>,
            <Button
              key="save"
              type="primary"
              className="bg-[#1890ff] mx-2"
              onClick={handleMemoSave}
              disabled={isDisabledEditBtn}
            >
              저장
            </Button>,
          ]}
        >
          <TextArea
            value={memoContent}
            onChange={(e) => onChangeMemo(e.target.value)}
            placeholder="메모 내용 입력"
            autoSize={{ minRows: 5 }}
          />
        </Modal>
        <BasicPopup
          isOpen={isOpenPopup}
          setIsOpen={setIsOpenPopup}
          icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
          title={basicPopupData.title}
          content=""
          handleOk={basicPopupData.handleOk}
          handleCancel={basicPopupData.handleCancel}
        ></BasicPopup>
      </>
    );
  },
);

export default NewCarLeaseList;
