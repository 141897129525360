import { getAdminRoleDetail } from '@/api/adminRole/getAdminRole';
import { putAdminRole } from '@/api/adminRole/putAdminRole';
import { Button, Col, Divider, Input, Row, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import MenuActionView from './MenuActionView';

const AdminRoleForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const labelStyle: React.CSSProperties = { padding: '8px 0', fontWeight: '700' };
  const style: React.CSSProperties = { padding: '8px 0' };

  const [roleName, setRoleName] = useState('');
  const [actionIdList, setActionIdList] = useState([]);
  const [roleId, setRoleId] = useState<number | null>(null);

  useQuery(['get-admin-role'], () => getAdminRoleDetail(Number(id)), {
    enabled: !!id,
    onSuccess: (data) => {
      setRoleId(data.id);
      setRoleName(data.name);
      setActionIdList(data.adminActions.map((item: IdNamePair) => item.id));
    },
    refetchOnWindowFocus: false,
  });

  const onChangeRoleName = (value: string) => {
    setRoleName(value);
  };

  const onClickGoToList = () => {
    navigate('/admin-role');
  };

  const handleClickSave = () => {
    const request = {
      actionIds: actionIdList,
      id: roleId || null,
      name: roleName,
    };
    updateAdminRoleMutation.mutate(request);
  };

  const updateAdminRoleMutation = useMutation((requestData: AdminRoleRequestType) => putAdminRole(requestData), {
    onSuccess: () => {
      message.success('저장되었습니다.');
      onClickGoToList();
    },
  });

  const updateActionId = (value: any) => {
    setActionIdList(value);
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>역할명
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="역할명 입력"
              style={{ width: '100%' }}
              value={roleName}
              onChange={(e) => onChangeRoleName(String(e.target.value))}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>메뉴 접근
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <MenuActionView
            actionIdList={actionIdList}
            updateActionId={(val) => updateActionId(val)}
            roleId={!!id ? Number(id) : null}
          />
        </Col>
      </Row>

      <Divider orientation="left"></Divider>

      <div className="flex justify-end">
        <Button onClick={onClickGoToList}>취소</Button>
        <Button type="primary" className="bg-[#1890ff] mx-2 w-20" onClick={handleClickSave}>
          저장하기
        </Button>
      </div>
    </>
  );
};

export default AdminRoleForm;
