import { getNotice } from '@/api/public';
import NoticeFilterSelector from '@/components/Notice/NoticeFilterSelector';
import NoticeSearchResult from '@/components/Notice/NoticeSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const NoticeList = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedNoticeFilterValue>({
    title: null,
    isDelete: null,
    startDate: null,
    endDate: null,
  });
  const [searchResultData, setSearchResultData] = useState<PaginatedResponse<Notice> | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getNoticeData = async (page?: number) => {
    try {
      const { title, isDelete, startDate, endDate } = selectedFilterValue;

      const queryParams = new URLSearchParams({
        title: title || '',
        isDelete: isDelete || '',
        startDate: startDate || '',
        endDate: endDate || '',
        page: String(page ? page : 1),
        size: '10',
      });
      const response = await getNotice(queryParams);
      setSearchResultData(response);
    } catch (error) {
      console.log(error);
      setSearchResultData(null);
    }
  };

  useEffect(() => {
    getNoticeData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h2 className="hidden">공지사항 관리</h2>
      <h3 className="text-gray-8">
        운영 관리 / <span className="font-semibold">공지사항 관리</span>
      </h3>
      <NoticeFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getNotificationData={() => getNoticeData(currentPage)}
      ></NoticeFilterSelector>

      <NoticeSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getNoticeData={() => getNoticeData(currentPage)}
      />

      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default NoticeList;
