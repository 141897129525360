import { getCallingHistory } from '@/api/public';
import CallingHistoryFilterSelector from '@/components/CallingHistory/CallingHistoryFilterSelector';
import CallingHistorySearchResult from '@/components/CallingHistory/CallingHistorySearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const CallingHistoryList = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedCallingHistoryFilterValue>({
    calledNumber: null,
    callingNumber: null,
    safetyNumber: null,
    startDate: null,
    endDate: null,
  });
  const [searchResultData, setSearchResultData] = useState<CallingHistoryResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getCallingHistoryData = async (page?: number) => {
    try {
      const { calledNumber, callingNumber, safetyNumber, startDate, endDate } = selectedFilterValue;

      const queryParams = new URLSearchParams({
        calledNumber: calledNumber || '',
        callingNumber: callingNumber || '',
        safetyNumber: safetyNumber || '',
        startDate: startDate || '',
        endDate: endDate || '',
        page: String(page ? page : 1),
        size: '10',
      });

      const response = await getCallingHistory(queryParams);
      setSearchResultData(response.data);
    } catch (error) {
      console.log(error);
      setSearchResultData(null);
    }
  };
  useEffect(() => {
    getCallingHistoryData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };
  return (
    <>
      <h2 className="hidden">영업 담당자 관리</h2>
      <h3 className="text-gray-8">
        영업 담당자 관리 / <span className="font-semibold">영업 담당자 통화기록</span>
      </h3>

      <CallingHistoryFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getCallingHistoryData={() => getCallingHistoryData(currentPage)}
      />
      <CallingHistorySearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getCallingHistoryData={() => getCallingHistoryData(currentPage)}
      />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default CallingHistoryList;
