import { getMeta } from '@/api/public';
import { Layout, Menu, theme } from 'antd';
import React, { useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import useFetchUserInfo from '@/hooks/useFetchUserInfo';
import { adminMembersEnumAtom } from '@/store/adminMembers';
import { adminMenusWithActionsAtom } from '@/store/adminMenusWithActions';
import { adminRoleFilterAtom } from '@/store/adminRole';
import { bannerEnumAtom } from '@/store/banner';
import { capitalCounselServicesEnumAtom } from '@/store/capitalCounselServices';
import { dashboardFilterEnumAtom } from '@/store/dashboard';
import { jobEnumAtom } from '@/store/job';
import { leaseServicesEnumAtom } from '@/store/leaseServices';
import { licenseEnumAtom } from '@/store/license';
import { memberAtom, membersEnumAtom } from '@/store/member';
import { notificationEnumAtom } from '@/store/notification';
import { oneStopServicesEnumAtom } from '@/store/oneStopServices';
import { productInquiryEnumAtom } from '@/store/productInquiry';
import { productPurchaseManageEnumAtom } from '@/store/productPurchaseManage';
import { productsEnumAtom } from '@/store/products';
import { purchaseAccompanyingServicesEnumAtom } from '@/store/purchaseAccompanyingServices';
import { transferAgencyServicesEnumAtom } from '@/store/transferAgencyServices';
import {
  CarOutlined,
  ContainerOutlined,
  DashboardOutlined,
  FlagOutlined,
  LineChartOutlined,
  PieChartOutlined,
  ReconciliationOutlined,
  TeamOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { MenuItemType } from 'antd/es/menu/interface';
import { useAtom } from 'jotai';
import { useQuery } from 'react-query';
import { SvgLogo } from '../Icon';
import HeaderComponent from './HeaderComponent';

const { Content, Footer, Sider } = Layout;

interface Menu {
  id: number;
  name: string;
  routeUrl: string;
}
const MainLayout = () => {
  const setMemberData = useFetchUserInfo();
  const [memberData] = useAtom(memberAtom);

  const getMenuIcon = (id: number) => {
    const iconMap: Record<number, JSX.Element> = {
      1: <PieChartOutlined />, // 대시보드
      2: <TeamOutlined />, // 회원관리
      3: <CarOutlined />, // 차량관리
      10: <DashboardOutlined />, // 번호판 관리
      13: <ReconciliationOutlined />, // 차량 구매문의
      14: <ContainerOutlined />, // 부가서비스 관리
      20: <WalletOutlined />, // 영업관리
      23: <FlagOutlined />, // 운영관리
      30: <LineChartOutlined />, // 관리자 및 데이터 관리
    };
    return iconMap[id];
  };

  const convertToMenuFormat = (items: any[]) => {
    return items?.map(({ id, name, routeUrl, children }) => {
      const menuItem: any = {
        label: name,
        key: id,
        link: routeUrl || null,
        icon: getMenuIcon(id),
      };

      if (children?.length) {
        menuItem.children = children.map(({ id, name, routeUrl }: Menu) => ({
          label: name,
          key: id,
          link: routeUrl || null,
        }));
      }

      return menuItem;
    });
  };

  const newMenuFormat = convertToMenuFormat(memberData.adminMenu);

  const navigate = useNavigate();
  const [, setMemberFilterInfo] = useAtom(membersEnumAtom);
  const [, setProductFilterInfo] = useAtom(productsEnumAtom);
  const [, setLicenseFilterInfo] = useAtom(licenseEnumAtom);
  const [, setTransferAgencyServicesFilterInfo] = useAtom(transferAgencyServicesEnumAtom);
  const [, setOneStopServicesFilterInfo] = useAtom(oneStopServicesEnumAtom);
  const [, setProductInquiryFilterInfo] = useAtom(productInquiryEnumAtom);
  const [, setproductPurchaseManageFilterInfo] = useAtom(productPurchaseManageEnumAtom);
  const [, setJobFilterInfo] = useAtom(jobEnumAtom);
  const [, setBannerFilterInfo] = useAtom(bannerEnumAtom);
  const [, setNotificationFilterInfo] = useAtom(notificationEnumAtom);
  const [, setPurchaseAccompanyingServicesFilterInfo] = useAtom(purchaseAccompanyingServicesEnumAtom);
  const [, setCapitalCounselServiceFilterInfo] = useAtom(capitalCounselServicesEnumAtom);
  const [, setDashboardFilterInfo] = useAtom(dashboardFilterEnumAtom);
  const [, setAdminMembersFilterInfo] = useAtom(adminMembersEnumAtom);
  const [, setLeaseServicesFilterInfo] = useAtom(leaseServicesEnumAtom);
  const [, setAdminRoleFilterInfo] = useAtom(adminRoleFilterAtom);
  const [, setAdminMenusWithActions] = useAtom(adminMenusWithActionsAtom);

  const getMetaInfo = async () => {
    try {
      const response = await getMeta();
      const res = response.data;
      setBannerFilterInfo(res.bannersFilterInfoResponse);
      setCapitalCounselServiceFilterInfo(res.capitalCounselServicesFilterInfoResponse);
      setDashboardFilterInfo(res.dashboardFilterResponse);
      setJobFilterInfo(res.jobFilterInfoResponse);
      setLicenseFilterInfo(res.licenseFilterInfoResponse);
      setMemberFilterInfo(res.membersAdminFilterInfoResponse);
      setNotificationFilterInfo(res.notificationFilterResponse);
      setOneStopServicesFilterInfo(res.oneStopServicesFilterInfoResponse);
      setProductInquiryFilterInfo(res.productInquiryFilterInfoResponse);
      setproductPurchaseManageFilterInfo(res.productPurchaseManageFilterResponse);
      setProductFilterInfo(res.productsFilterInfoResponse);
      setPurchaseAccompanyingServicesFilterInfo(res.purchaseAccompanyingServicesFilterResponse);
      setTransferAgencyServicesFilterInfo(res.transferAgencyServicesFilterResponse);
      setLeaseServicesFilterInfo(res.leaseServicesFilterResponse);
      setAdminMembersFilterInfo(res.adminMembersFilterInfoResponse);
      setAdminRoleFilterInfo(res.adminRoleFilterResponse);
      setAdminMenusWithActions(res.adminMenusWithActionsResponse);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-meta'], getMetaInfo, {
    staleTime: 600000,
  });

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleMenuClick = ({ key }: { key: string }) => {
    let selectedItem: any = null;

    for (const item of newMenuFormat) {
      if (String(item.key) === key) {
        selectedItem = item;
        break;
      }

      if (item.children) {
        const foundChild = item.children.find((child: MenuItemType) => String(child.key) === key);
        if (foundChild) {
          selectedItem = foundChild;
          break;
        }
      }
    }

    if (selectedItem?.link) {
      navigate(selectedItem.link);
    }
  };

  useEffect(() => {
    getMemberData();
  }, []);

  const getMemberData = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      setMemberData(token, false);
    } else {
      navigate('/login');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', minWidth: '756px' }}>
      <Sider
        width={220}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          background: '#ffffff',
          zIndex: 100,
        }}
      >
        <Link to="/">
          <div className="demo-logo-vertical flex justify-center items-center h-[60px] cursor-pointer">
            <SvgLogo color={'#1E42A6'} />
            <span className="ml-3 text-lg font-bold">관리자센터</span>
          </div>
        </Link>
        <Menu mode="inline" items={newMenuFormat} onClick={handleMenuClick} />
      </Sider>
      <Layout style={{ marginLeft: 220 }}>
        <HeaderComponent />
        <Content
          style={{
            margin: '16px',
            padding: 24,
            minHeight: 360,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            overflow: 'initial',
          }}
        >
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()}직트럭</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
