import React from 'react';

import apiManager from '@/api/ApiManager';
import { MULTIPART_FORM_DATA } from '@/const/headers';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';

interface FileUploaderProps {
  truckNumber: string;
  fileNameMaxLength?: number;
  setIsDisabledEditBtn?: React.Dispatch<React.SetStateAction<boolean>>;
  setProductImagesList: React.Dispatch<React.SetStateAction<ProductImage[]>>;
}

interface UploadItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  fileNameMaxLength?: number;
}

const MultiFileUploader = ({
  truckNumber,
  fileNameMaxLength,
  setIsDisabledEditBtn,
  setProductImagesList,
}: FileUploaderProps) => {
  const openFileDialog = () => {
    const inputElement = document.getElementById(`upload-button`) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    uploadImage(e.target.files);
  };

  const uploadImage = async (filesList: FileList) => {
    const formData = new FormData();
    const filesArray = Array.from(filesList);

    if (!truckNumber) {
      message.error('차량번호를 입력해주세요.', 1);
      return;
    }

    filesArray.forEach((file, index) => {
      formData.append(`uploadFile`, file);
    });
    formData.append('truckNumber', truckNumber);

    try {
      const response = await apiManager.post('/admin/v1/products-images/multi', formData, {
        headers: {
          'Content-Type': MULTIPART_FORM_DATA,
        },
      });

      if (response.data) {
        const fileArray = response.data;

        setProductImagesList((prev) => {
          let updatedList = [...prev];
          updatedList = updatedList.map((item) => ({
            ...item,
            value: '', // 한번에 올리기시 일단 초기화
          }));

          updatedList = updatedList.map((item, index) => ({
            ...item,
            value: fileArray[index]?.url.toString() || item.value,
          }));
          return updatedList;
        });
        if (!!setIsDisabledEditBtn) {
          setIsDisabledEditBtn(false);
        }
      }
    } catch (error: any) {
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Button icon={<UploadOutlined />} onClick={openFileDialog}>
        한번에 올리기
      </Button>
      <input
        id={`upload-button`}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => handleChange(e)}
        multiple
      />
    </>
  );
};

export default MultiFileUploader;
