import React, { useEffect, useState } from 'react';

import { getMemberList } from '@/api/public';
import FilterSelector from '@/components/Member/FilterSelector';
import SearchResult from '@/components/Member/SearchResult';
import { Pagination, message } from 'antd';
import dayjs from 'dayjs';

const MemberList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilterValue, setSelectedFilterValue] = useState<MemberSelectedFilterValue>({
    name: '',
    phoneNumber: '',
    startDate: String(dayjs('2023-12-01').format('YYYY-MM-DD')),
    endDate: '',
    socialType: '',
    isAgreeMarketing: '',
    partnerId: '',
    ascending: false,
  });
  const [searchResultData, setSearchResultData] = useState<MemberResponse | null>(null);

  const getMemberData = async (page?: number) => {
    try {
      const { name, phoneNumber, startDate, endDate, socialType, isAgreeMarketing, partnerId, ascending } =
        selectedFilterValue;

      const queryParams = new URLSearchParams({
        name,
        phoneNumber,
        startDate,
        endDate,
        socialType,
        isAgreeMarketing: String(isAgreeMarketing),
        partnerId,
        ascending: String(ascending),
        page: String(page ? page : 1),
        size: '10',
      });

      const response = await getMemberList(queryParams);
      setSearchResultData(response.data);
    } catch (error: any) {
      console.log(error);
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getMemberData(currentPage);
  }, [currentPage, selectedFilterValue.ascending]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const updateAscending = () => {
    setSelectedFilterValue({ ...selectedFilterValue, ascending: !selectedFilterValue.ascending });
  };

  return (
    <>
      <h3 className="text-gray-8">회원관리</h3>
      <FilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getMemberData={() => getMemberData(currentPage)}
      ></FilterSelector>
      <SearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        getMemberData={() => getMemberData(currentPage)}
        ascending={selectedFilterValue.ascending}
        updateAscending={updateAscending}
      ></SearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default MemberList;
