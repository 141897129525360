import { getOneStopServices } from '@/api/public';
import OneStopServicesFilterSelector from '@/components/OneStopServices/OneStopServicesFilterSelector';
import OneStopServicesSearchResult from '@/components/OneStopServices/OneStopServicesSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const OneStopServicesListAll = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedOneStopServicesFilterValue>({
    status: null,
    name: null,
    phoneNumber: null,
    startDate: null,
    endDate: null,
    ascending: false,
  });
  const [searchResultData, setSearchResultData] = useState<OneStopServicesResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getOneStopServicesData = async (page?: number) => {
    try {
      const { status, name, phoneNumber, startDate, endDate, ascending } = selectedFilterValue;

      const queryParams = new URLSearchParams({
        name: name || '',
        status: status || '',
        phoneNumber: phoneNumber || '',
        startDate: startDate || '',
        endDate: endDate || '',
        ascending: String(ascending),
        page: String(page ? page : 1),
        size: '10',
      });
      const response = await getOneStopServices(queryParams);
      setSearchResultData(response.data);
    } catch (error: any) {
      console.log(error);
      setSearchResultData(null);
    }
  };

  useEffect(() => {
    getOneStopServicesData(currentPage);
  }, [currentPage, selectedFilterValue.ascending]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const updateAscending = () => {
    setSelectedFilterValue({ ...selectedFilterValue, ascending: !selectedFilterValue.ascending });
  };

  return (
    <>
      <h2 className="hidden">판매대행 서비스 관리 - 신청 내역</h2>
      <h3 className="text-gray-8">
        부가 서비스 관리 / <span className="font-semibold">판매대행 서비스</span>
      </h3>

      <OneStopServicesFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getOneStopServicesData={() => getOneStopServicesData(currentPage)}
      ></OneStopServicesFilterSelector>

      <OneStopServicesSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getOneStopServicesData={() => getOneStopServicesData(currentPage)}
        ascending={selectedFilterValue.ascending}
        updateAscending={updateAscending}
      ></OneStopServicesSearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default OneStopServicesListAll;
