import { deleteAdminMember, postAdminMember } from '@/api/public';
import { patchAdminMembers } from '@/api/services/patchServices';
import { adminMembersEnumAtom } from '@/store/adminMembers';
import { adminRoleFilterAtom } from '@/store/adminRole';
import { convertToAdminRoleOptions, convertToSelectOptions } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Input, Modal, Select, Table, TableColumnsType, message } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import BasicPopup from '../Common/BasicPopup';
import SortingButton from '../Common/SortingButton';

interface DataType {
  id: number;
  key: React.Key;
  name: string;
  phoneNumber: string;
  role: EnumPresenter;
  createdDate: string;
  modifiedDate: string;
  adminRole?: AdminRole;
}

const AdminMemberSearchResult = ({
  selectedFilterValue,
  data,
  getAdminMemberData,
  ascending,
  updateAscending,
}: AdminMemberResponseDataProps) => {
  const [adminMembersFilterEnum] = useAtom(adminMembersEnumAtom);
  const [adminRoleFilterEnum] = useAtom(adminRoleFilterAtom);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [adminMemberData, setAdminMemberDate] = useState({
    loginId: '',
    name: '',
    phoneNumber: '',
    adminRoleId: '',
  });
  const [basicPopupData, setBasicPopupData] = useState({
    title: '',
    handleOk: () => {},
    handleCancel: () => {},
  });
  const columns: TableColumnsType<DataType> = [
    {
      title: '이름',
      dataIndex: 'name',
    },
    {
      title: '휴대폰 번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: <SortingButton title="가입일시" isAscending={ascending} onclick={updateAscending} />,
      dataIndex: 'createdDate',
    },
    {
      title: '역할',
      dataIndex: 'adminRole',
      render: (text, record) => (
        <Select
          value={record.adminRole?.name}
          style={{ width: 150 }}
          options={selectOptions.adminRole}
          onChange={(value) => handleStatusChange(value, record)}
        >
          record.adminRole?.name,
        </Select>
      ),
    },
  ];

  const handleStatusChange = (value: string, record: DataType) => {
    const request = {
      id: record.id,
      adminRoleId: value,
    };
    setBasicPopupData({
      title: '역할 변경하시겠어요?',
      handleOk: () => onClickStatusChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenPopup(true);
  };

  const onClickStatusChangeButton = (requestData: { id: number; adminRoleId: string }) => {
    patchAdminMembersMutation.mutate(requestData);
  };

  const patchAdminMembersMutation = useMutation(
    (requestData: { id: number; adminRoleId: string }) =>
      patchAdminMembers(requestData.id, { adminRoleId: requestData.adminRoleId }),
    {
      onSuccess: () => {
        message.success('상태가 변경되었습니다.', 2);
        getAdminMemberData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  const onClickCancelButton = () => {
    setIsOpenPopup(false);
  };

  const convertToAdminMembersSelectOption = (
    adminMembersEnum: AdminMembersEnumType,
    adminRoleFilterEnum: AdminRoleFilterInfoType,
  ) => {
    return {
      role: convertToSelectOptions(adminMembersEnum.role),
      adminRole: convertToAdminRoleOptions(adminRoleFilterEnum.adminRoles),
    };
  };
  const selectOptions = convertToAdminMembersSelectOption(adminMembersFilterEnum, adminRoleFilterEnum);
  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      name: item.name,
      phoneNumber: item.phoneNumber,
      role: item.role,
      createdDate: item.createdDate,
      modifiedDate: item.modifiedDate,
      adminRole: item.adminRole,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const showConfirm = () => {
    setIsOpenAddModal(true);
  };

  const showDeleteConfirm = () => {
    setIsOpenDeletePopup(true);
  };

  const onCloseModal = () => {
    setIsOpenAddModal(false);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAdminMemberDate({ ...adminMemberData, [name]: value });
  };

  const onChangeSelect = (name: string, value: string) => {
    setAdminMemberDate({ ...adminMemberData, [name]: value });
  };

  const postAdminMemberMutation = useMutation(
    (requestData: { loginId: string; name: string; phoneNumber: string }) => postAdminMember(requestData),
    {
      onSuccess: ({ data }) => {
        const { loginId, name, phoneNumber, adminRoleId } = data;
        message.success('관리자 계정이 생성되었어요.', 2);
        setAdminMemberDate({ loginId, name, phoneNumber, adminRoleId });
        setIsOpenAddModal(false);
        getAdminMemberData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const onClickAddAdminButton = () => {
    const { loginId, name, phoneNumber, adminRoleId } = adminMemberData;
    const request = {
      loginId,
      name,
      phoneNumber,
      adminRoleId,
    };
    postAdminMemberMutation.mutate(request);
    setIsOpenAddModal(false);
  };

  const onClickDelete = () => {
    setIsOpenDeletePopup(false);

    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      deleteAdminMemberMutation.mutate(String(firstSelectedId));
    } else {
      message.error('회원이 선택되지 않았어요. 삭제할 회원을 선택해주세요.');
    }
  };

  const deleteAdminMemberMutation = useMutation(deleteAdminMember, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getAdminMemberData();
    },
    onError: (error: AxiosError) => {
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 회원수</span> {data?.totalElements}명
          </div>
          <div>
            <Button onClick={showConfirm}>추가</Button>
            <Button className="mx-2" danger onClick={showDeleteConfirm}>
              삭제
            </Button>
          </div>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <Modal title="관리자 계정 등록" open={isOpenAddModal} footer={null} onCancel={onCloseModal} centered>
        <div style={{ textAlign: 'center' }}>
          <div>
            <div className="flex items-center justify-center p-2">
              <span className="w-28">
                <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>아이디:
              </span>
              <Input placeholder="아이디" name="loginId" value={adminMemberData.loginId} onChange={onChangeInput} />
            </div>

            <div className="flex items-center justify-center p-2">
              <span className="w-28">
                <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>이름:{' '}
              </span>
              <Input placeholder="이름" name="name" value={adminMemberData.name} onChange={onChangeInput} />
            </div>
          </div>
          <div className="flex items-center justify-center p-2">
            <span className="w-28">휴대폰 번호: </span>
            <Input
              placeholder="휴대폰 번호"
              name="phoneNumber"
              value={adminMemberData.phoneNumber}
              onChange={onChangeInput}
            />
          </div>
          <div className="flex items-center p-2 justify-left ">
            <span className="w-28 ">관리자 역할: </span>
            <Select
              className="w-64"
              placeholder="관리자 역할"
              options={selectOptions.adminRole}
              value={adminMemberData.adminRoleId}
              onChange={(value) => onChangeSelect('adminRoleId', value)}
            />
          </div>
          <div className="flex justify-end">
            <Button className="w-[90px]" onClick={() => onCloseModal()}>
              닫기
            </Button>
            <Button type="primary" onClick={onClickAddAdminButton} className="w-[90px] bg-[#1890ff] mx-2">
              완료
            </Button>
          </div>
        </div>
      </Modal>

      <BasicPopup
        isOpen={isOpenDeletePopup}
        setIsOpen={setIsOpenDeletePopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="정말 삭제하시겠어요?"
        content=""
        handleOk={() => onClickDelete()}
        handleCancel={() => setIsOpenDeletePopup(false)}
      ></BasicPopup>

      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={basicPopupData.handleOk}
        handleCancel={basicPopupData.handleCancel}
      ></BasicPopup>
    </>
  );
};

export default AdminMemberSearchResult;
