import { ADMIN_ROLE_FORBIDDEN, AUTHENTICATION_FAIL_RETRY_LOGIN } from '@/const/errorCode';
import { APPLICATION_JSON } from '@/const/headers';
import { message } from 'antd';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

const apiManager: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  timeout: 500000,
  headers: {
    'Content-Type': APPLICATION_JSON,
    'X-Requested-With': 'XMLHttpRequest',
  },
});

apiManager.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (config.url) {
      if (!config.url.includes('login')) {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      } else {
        if (config.headers['Authorization']) {
          delete config.headers['Authorization'];
        }
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

let isUnauthorizedHandled = false;
apiManager.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorResponse = error.response;

    if (error.response.data.code === ADMIN_ROLE_FORBIDDEN && !isUnauthorizedHandled) {
      isUnauthorizedHandled = true;
      message.error(errorResponse.data.message);
      setTimeout(() => {
        isUnauthorizedHandled = false;
      }, 3000);
      return Promise.reject(errorResponse.data.code);
    } else if (error.response.data.code === AUTHENTICATION_FAIL_RETRY_LOGIN) {
      message.error(errorResponse.data.message);
      localStorage.removeItem('token');
    } else {
      return Promise.reject(errorResponse?.data || errorResponse);
    }
  },
);

export default apiManager;
