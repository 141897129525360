import { deleteMember, patchMemberInfo } from '@/api/public';
import { Button, Input, Modal, Table, TableColumnsType, message } from 'antd';
import React, { useEffect, useState } from 'react';

import BasicPopup from '@/components/Common/BasicPopup';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import ExcelDownloadButton from '../Common/Button/ExcelDownloadButton';
import SortingButton from '../Common/SortingButton';

interface DataType {
  id: number;
  key: React.Key;
  createdDate: string;
  email: string;
  modifiedDate: string;
  name: string;
  phoneNumber: string;
  profileImageUrl: string;
  terms: true;
  type: string;
  socialType: string;
  marketing: boolean;
  isTheUnbanMember: boolean;
  externalJoinDateTime: string;
  referralCodes: string;
}

const SearchResult = ({
  selectedFilterValue,
  data,
  getMemberData,
  ascending,
  updateAscending,
}: MemberResponseDataProps) => {
  const [selectedId, setSelectedId] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPoppup, setIsOpenPopup] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);
  const [excelDownloadParams, setExcelDownloadParams] = useState<MemberExcelParams>({});
  const [memberData, setMemberData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: '이름',
      dataIndex: 'name',
    },
    {
      title: '휴대폰 번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: '이메일',
      dataIndex: 'email',
    },

    {
      title: '소셜로그인 경로',
      dataIndex: 'socialType',
    },
    {
      title: '마케팅 동의여부',
      dataIndex: 'marketing',
      render: (text, record) => (record.marketing ? '동의' : '미동의'),
    },
    {
      title: <SortingButton title="가입일시" isAscending={ascending} onclick={updateAscending} />,
      dataIndex: 'createdDate',
    },
    {
      title: '외부 가입요청 일시',
      dataIndex: 'externalJoinDateTime',
      render: (text, record) => (record.externalJoinDateTime ? record.externalJoinDateTime : '-'),
    },
    {
      title: '유입경로(추천인코드)',
      dataIndex: 'referralCodes',
      render: (text, record) => (record.referralCodes ? record.referralCodes : '-'),
    },
    {
      title: '최근 수정일',
      dataIndex: 'modifiedDate',
    },
    {
      title: '수정',
      dataIndex: 'edit',
      render: (text, record) => <Button onClick={() => onClickOpenModal(record)}>수정</Button>,
    },
  ];

  const onClickOpenModal = (memberInfo: any) => {
    setIsOpenModal(true);
    setMemberData({ name: memberInfo.name, phoneNumber: memberInfo.phoneNumber, email: memberInfo.email });
    setSelectedId(memberInfo.id);
  };

  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      createdDate: item.createdDate,
      email: item.email,
      modifiedDate: item.modifiedDate,
      name: item.name,
      phoneNumber: item.phoneNumber,
      profileImageUrl: item.profileImageUrl,
      terms: item.terms,
      type: item.type.code,
      socialType: item.socialType.map((item) => item.desc).join(', ') || '-',
      marketing: item.marketing,
      isTheUnbanMember: item.isTheUnbanMember,
      externalJoinDateTime: item.externalJoinDateTime || '',
      referralCodes: item.referralCodes || '',
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setExcelDownloadParams({
      name: selectedFilterValue?.name ? selectedFilterValue.name : '',
      phoneNumber: selectedFilterValue?.phoneNumber ? selectedFilterValue.phoneNumber : '',
      startDate: selectedFilterValue?.startDate ? selectedFilterValue.startDate : '',
      endDate: selectedFilterValue?.endDate ? selectedFilterValue.endDate : '',
      socialType: selectedFilterValue?.socialType ? selectedFilterValue.socialType : '',
      isAgreeMarketing: selectedFilterValue?.isAgreeMarketing ? selectedFilterValue.isAgreeMarketing : '',
      ascending: selectedFilterValue?.ascending ? String(selectedFilterValue.ascending) : '',
      partnerId: selectedFilterValue?.partnerId ? String(selectedFilterValue.partnerId) : '',
    });
  }, [selectedFilterValue]);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onClickDelete = () => {
    setIsOpenPopup(false);

    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      deleteMemberMutation.mutate(String(firstSelectedId));
    } else {
      message.error('회원이 선택되지 않았어요. 삭제할 회원을 선택해주세요.');
    }
  };

  const deleteMemberMutation = useMutation(deleteMember, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getMemberData();
    },
    onError: (error: AxiosError) => {
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    },
  });

  const patchMemberInfoMutation = useMutation(
    (requestData: { name: string; phoneNumber: string; email: string }) =>
      patchMemberInfo(String(selectedId), requestData),
    {
      onSuccess: ({ data }) => {
        const { name, phoneNumber, email } = data;
        message.success('회원 정보 수정이 완료되었어요.', 2);
        setMemberData({ name, phoneNumber, email });
        setIsDisabledEditBtn(true);
        setIsOpenModal(false);
        getMemberData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const hasSelected = selectedRowKeys.length > 0;

  const showConfirm = () => {
    setIsOpenPopup(true);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDisabledEditBtn(false);
    const { name, value } = e.target;

    setMemberData({ ...memberData, [name]: value });
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
    setIsDisabledEditBtn(true);
  };

  const onClickUpadateMemberInfo = () => {
    const { name, phoneNumber, email } = memberData;
    const request = {
      name,
      phoneNumber,
      email,
    };
    patchMemberInfoMutation.mutate(request);
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 회원수</span> {data?.totalElements}명
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}명 선택됨` : ''}</span>
          </div>
          <div>
            <ExcelDownloadButton
              apiPath="/admin/v1/members/excel"
              params={excelDownloadParams}
              buttonText="엑셀 다운로드"
            ></ExcelDownloadButton>
            <Button danger onClick={showConfirm}>
              삭제
            </Button>
          </div>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="정말 삭제하시겠어요?"
        content=""
        handleOk={() => onClickDelete()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>

      <Modal title="회원 정보 수정" open={isOpenModal} footer={null} onCancel={onCloseModal} centered>
        <div style={{ textAlign: 'center' }}>
          <div>
            <div className="flex items-center justify-center p-2">
              <span className="w-28">
                <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>이름:
              </span>
              <Input placeholder="이름" name="name" value={memberData.name} onChange={onChangeInput} />
            </div>
            <div className="flex items-center justify-center p-2">
              <span className="w-28">
                <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>휴대폰 번호:{' '}
              </span>
              <Input
                placeholder="휴대폰 번호"
                name="phoneNumber"
                value={memberData.phoneNumber}
                onChange={onChangeInput}
              />
            </div>
            <div className="flex items-center justify-center p-2">
              <span className="w-28">이메일: </span>
              <Input placeholder="이메일" name="email" value={memberData.email} onChange={onChangeInput} />
            </div>
          </div>
          <div className="flex justify-end">
            <Button className="w-[90px]" onClick={() => onCloseModal()}>
              닫기
            </Button>
            <Button
              type="primary"
              onClick={onClickUpadateMemberInfo}
              className="w-[90px] bg-[#1890ff] mx-2"
              disabled={isDisabledEditBtn}
            >
              완료
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SearchResult;
