import { patchProductPerchase, patchProductToConsignment, patchProductsDetail } from '@/api/public';
import BasicPopup from '@/components/Common/BasicPopup';
import SortingButton from '@/components/Common/SortingButton';
import { productPurchaseManageEnumAtom } from '@/store/productPurchaseManage';
import { productsEnumAtom } from '@/store/products';
import {
  convertToSalesPeopleOptions,
  convertToSelectOptions,
  formatNumberWithComma,
  getFormatDate,
} from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Input, Modal, Select, Table, TableColumnsType, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ExcelDownloadButton from '../../Common/Button/ExcelDownloadButton';

interface StatusChangeRequest {
  id: string;
  productPurchaseManageStatus: string;
}

const SpeedSearchResult = ({
  selectedFilterValue,
  data,
  getTruckData,
  ascending,
  setAscending,
}: ProductResponseDataProps) => {
  const [excelDownloadParams, setExcelDownloadParams] = useState<ProductExcelParams>({});
  const [isDisabledPurchaseBtn, setIsDisabledPurchaseBtn] = useState(true);
  const [isDisabledConsignmentChangeBtn, setIsDisabledConsignmentChangeBtn] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [salesPeopleId, setSalesPeopleId] = useState('');
  const [price, setPrice] = useState('');

  const onclickSortingButton = () => {
    if (setAscending) {
      setAscending(!ascending);
    }
  };
  const columns: TableColumnsType<ProductListDataType> = [
    { title: '제시번호', dataIndex: 'productsNumber' },
    { title: '차량명', dataIndex: 'truckName' },
    { title: '차량번호', dataIndex: 'truckNumber' },
    { title: '상태', dataIndex: 'status' },
    { title: '제조사', dataIndex: 'manufacturerName' },
    { title: '모델', dataIndex: 'modelName' },
    { title: '연식', dataIndex: 'firstRegistrationDate' },
    { title: '적재함 길이', dataIndex: 'loadedInnerLength' },
    { title: '변속기', dataIndex: 'transmission' },
    { title: '주행거리', dataIndex: 'distance' },
    { title: '판매가격', dataIndex: 'price' },
    {
      title: <SortingButton title="매입견적 요청일자" isAscending={ascending} onclick={onclickSortingButton} />,
      dataIndex: 'speedRequestTime',
    },
    {
      title: '관리 담당자',
      dataIndex: 'salesPeople',
      render: (text, record) => (
        <>
          <Select
            className="my-2 mr-4"
            style={{ width: 180 }}
            placeholder="관리 담당자"
            optionFilterProp="children"
            options={selectOptions.salesPeople}
            value={record?.salesPeople}
            onChange={(value) => onChangeSelect(value, record)}
            onClick={(e) => e.stopPropagation()}
          ></Select>
        </>
      ),
    },
    {
      title: '삭제여부',
      dataIndex: 'isDelete',
      render: (text, record) => (record.isDelete == true ? '삭제' : '미삭제'),
    },
    {
      title: '더운반 차량 여부',
      dataIndex: 'isTheUnbanProduct',
      render: (text, record) => (record.isTheUnbanProduct == true ? '더운반' : '자체'),
    },
    {
      title: '관리상태',
      dataIndex: 'productPurchaseManage',
      render: (text, record) => (
        <Select
          value={record.productManage?.status?.desc}
          options={selectOptions.productPurchaseManageStatus}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(value, record)}
          onClick={(e) => e.stopPropagation()}
        ></Select>
      ),
    },
    {
      title: '관리메모',
      dataIndex: 'productPurchaseManage',
      render: (text, record) => (
        <>
          {record?.productManage?.comment ? (
            <>
              <p
                className="mr-3 overflow-hidden font-bold break-all whitespace-no-wrap text-ellipsis"
                style={{
                  maxWidth: '500px',
                  maxHeight: '200px',
                }}
              >
                {record?.productManage?.comment}
              </p>

              <Button onClick={(e) => handlePurchaseManageButtonClick(e, record)}>수정</Button>
            </>
          ) : (
            <Button onClick={(e) => handlePurchaseManageButtonClick(e, record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const [productPurchaseManageFilterEnum, setproductPurchaseManageFilterEnum] = useAtom(productPurchaseManageEnumAtom);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPoppup, setIsOpenPopup] = useState(false);
  const [isPurchaseManageModalIsOpen, setPurchaseManageModalIsOpen] = useState(false);
  const [purchaseManageComment, setPurchaseManageComment] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [statusRequest, setStatusRequest] = useState<StatusChangeRequest>({ id: '', productPurchaseManageStatus: '' });
  const [productFilterEnum] = useAtom(productsEnumAtom);
  const [isOpenSalesPeopleChangePopup, setIsOpenSalesPeopleChangePopup] = useState(false);
  const [basicPopupData, setBasicPopupData] = useState({
    title: '',
    handleOk: () => {},
    handleCancel: () => {},
  });

  const onChangeSelect = (value: string, record: ProductListDataType) => {
    const request = {
      id: String(record.id),
      salesPeopleId: value,
    };
    setBasicPopupData({
      title: '담당자를 변경하시겠어요?',
      handleOk: () => onClickSalesPeopleChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenSalesPeopleChangePopup(true);
  };
  const onClickCancelButton = () => {
    setIsOpenSalesPeopleChangePopup(false);
  };

  const onClickSalesPeopleChangeButton = (requestData: { id: string; salesPeopleId: string }) => {
    patchSalesPeopleModifyMutation.mutate(requestData);
  };

  const patchSalesPeopleModifyMutation = useMutation(
    (requestData: { id: string; salesPeopleId: string }) => patchProductsDetail(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('담당자가 변경되었습니다.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsOpenSalesPeopleChangePopup(false);
      },
    },
  );

  const handlePurchaseManageButtonClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, data: ProductListDataType) => {
    setPurchaseManageComment(data.productManage?.comment);
    setSelectedProductId(String(data.id));
    setPurchaseManageModalIsOpen(true);
    e.stopPropagation();
  };

  const handlePurchaseManageModalClose = () => {
    setPurchaseManageModalIsOpen(false);
  };

  const patchPurchaseManageCommentMutation = useMutation(
    (requestData: { id: string; productPurchaseManageComment: string }) =>
      patchProductsDetail(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('관리메모가 작성되었어요.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const patchPurchaseManageStatusMutation = useMutation(
    (requestData: { id: string; productPurchaseManageStatus: string }) =>
      patchProductsDetail(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('상세 정보가 수정되었습니다.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const handleStatusChange = (value: string, record: ProductListDataType) => {
    setIsOpenPopup(true);
    const request = {
      id: String(record.id),
      productPurchaseManageStatus: value,
    };
    setStatusRequest(request);
  };

  const onClickStatusChangeButton = () => {
    patchPurchaseManageStatusMutation.mutate(statusRequest);
    setIsOpenPopup(false);
  };

  const handlePurchaseManageSave = () => {
    if (selectedProductId) {
      const request: any = {};
      request.id = selectedProductId;
      request.productPurchaseManageComment = purchaseManageComment;
      patchPurchaseManageCommentMutation.mutate(request);
    }
    setPurchaseManageModalIsOpen(false);
  };

  const onChangeComment = (comment: string) => {
    setPurchaseManageComment(comment);
  };

  const convertProductsPurchaseManageFilterInfoToSelectOptions = (
    productPurchaseManageFilterEnum: ProductPurchaseManageFilterInfoType,
    productFilterEnum: ProductsFilterInfoType,
  ) => {
    return {
      productPurchaseManageStatus: convertToSelectOptions(productPurchaseManageFilterEnum.status),
      salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
    };
  };

  const selectOptions = convertProductsPurchaseManageFilterInfoToSelectOptions(
    productPurchaseManageFilterEnum,
    productFilterEnum,
  );
  const updatedTableData =
    data?.data?.map((item) => {
      const [date, time] = item.speedRequestTime ? item.speedRequestTime.split(' ') : ['', ''];

      return {
        id: item.id,
        key: item.id,
        productsNumber: item.productsNumber,
        truckName: item.truckName,
        truckNumber: item.truckNumber,
        status: item.status?.desc || '',
        manufacturerName: item.manufacturerName,
        modelName: item.modelName,
        firstRegistrationDate: getFormatDate(String(item.firstRegistrationDate), ['year', 'month'], true),
        loadedInnerLength: item.loadedInnerLength,
        transmission: item.transmission?.desc || '',
        distance: formatNumberWithComma(String(item.distance)),
        price: item.price,
        speedRequestTime: `${date}\n${time}` || '',
        productPurchaseManage: item.productPurchaseManage,
        salesPeople: item.salesPeople?.id ? `${item.salesPeople?.name}(${item.salesPeople?.phoneNumber})` : '-',
        productManage: item.productPurchaseManage,
        createdDate: item.createdDate,
        isDelete: item.isDelete,
        isTheUnbanProduct: item.isTheUnbanProduct,
      };
    }) || [];

  const [tableData, setTableData] = useState<ProductListDataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const [firstSelectedId] = newSelectedRowKeys;
    const record = data?.data.find((item) => item.id === firstSelectedId);
    setIsDisabledPurchaseBtn(record?.status?.code != 'SALE' && record?.status?.code != 'COMPLETED');
    setIsDisabledConsignmentChangeBtn(record?.status?.code != 'SALE' || record?.type?.code != 'SPEED');
  };

  const rowProps = (record: ProductListDataType) => ({
    onClick: () => {
      navigate(`/vehicles/speed/${record.id}`);
    },
  });

  const rowSelection: TableRowSelection<ProductListDataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onClickAssurancePurchaseBtn = () => {
    if (selectedRowKeys.length == 0) {
      message.error('차량이 선택되지 않았어요');
      return;
    }
    patchProductPerchaseMutation.mutate();
  };

  const patchProductPerchaseMutation = useMutation(() => patchProductPerchase(String(selectedRowKeys)), {
    onSuccess: ({ data }) => {
      message.success('매입 처리가 완료되었어요.', 2);
      if (data.id) {
        navigate(`/vehicles/assurance/${data.id}`);
      }
    },
    onError: (error: AxiosError) => {
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    },
  });

  const patchProductToConsignmentMutation = useMutation(
    (requestData: { salesPeopleId: string; price: string }) =>
      patchProductToConsignment(String(selectedRowKeys), requestData),
    {
      onSuccess: ({ data }) => {
        message.success('판매대행 매물로 변경되었어요.', 2);
        if (data.id) {
          navigate(`/vehicles/consignment/${data.id}`);
        }
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          if (error.message) {
            message.error(error.message, 2);
          } else {
            console.log(error);
          }
        }
      },
    },
  );

  const onClickConsignmentPurchaseBtn = () => {
    if (selectedRowKeys.length == 0) {
      message.error('차량이 선택되지 않았어요');
      return;
    }
    setIsOpenModal(true);
  };

  const showConfirm = () => {
    setIsOpenPopup(true);
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    setSalesPeopleId(salesPeopleId);
    setPrice('');
  };

  const handleModalSave = () => {
    const request = {
      salesPeopleId: String(salesPeopleId),
      price: String(price),
    };
    patchProductToConsignmentMutation.mutate(request);

    handleModalClose();
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체차량</span> {data?.totalElements}대
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
          <div>
            <Button
              onClick={onClickAssurancePurchaseBtn}
              disabled={isDisabledPurchaseBtn || patchProductPerchaseMutation.isLoading}
              loading={patchProductPerchaseMutation.isLoading}
              className="px-4 py-2 mr-2 text-white bg-blue-500 border-none rounded-md"
            >
              직트럭 상품용으로 매입하기
            </Button>
            <Button
              disabled={isDisabledConsignmentChangeBtn || patchProductToConsignmentMutation.isLoading}
              loading={patchProductToConsignmentMutation.isLoading}
              className="px-4 py-2 mr-2 text-white bg-green-500 border-none rounded-md"
              onClick={onClickConsignmentPurchaseBtn}
            >
              판매대행 차량으로 전환하기
            </Button>
            <ExcelDownloadButton
              apiPath="/admin/v1/products/excel"
              params={excelDownloadParams}
              buttonText="엑셀 다운로드"
            ></ExcelDownloadButton>
            <Button danger onClick={showConfirm}>
              삭제
            </Button>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          onRow={rowProps}
          pagination={false}
        />
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="상태를 변경하겠습니까?"
        content=""
        handleOk={() => onClickStatusChangeButton()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>
      <Modal
        title="메모 보기"
        open={isPurchaseManageModalIsOpen}
        onCancel={handlePurchaseManageModalClose}
        footer={[
          <Button key="close" onClick={handlePurchaseManageModalClose}>
            닫기
          </Button>,
          <Button key="save" type="primary" className="bg-[#1890ff] mx-2" onClick={handlePurchaseManageSave}>
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={purchaseManageComment}
          onChange={(e) => onChangeComment(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>

      <BasicPopup
        isOpen={isOpenSalesPeopleChangePopup}
        setIsOpen={setIsOpenSalesPeopleChangePopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={() => basicPopupData.handleOk()}
        handleCancel={() => basicPopupData.handleCancel()}
      ></BasicPopup>

      <Modal
        title="판매대행으로 전환하기"
        open={isOpenModal}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleModalSave}
            disabled={!!!salesPeopleId || !!!price}
          >
            저장
          </Button>,
        ]}
      >
        <div className="flex items-center justify-center mb-4">
          <span className="w-20">영업담당자:</span>
          <Select
            style={{ width: 200 }}
            placeholder="영업담당자"
            options={selectOptions.salesPeople}
            value={salesPeopleId || null}
            onChange={(value) => setSalesPeopleId(value)}
          />
        </div>

        <div className="flex items-center justify-center mb-4">
          <span className="w-20">판매 가격:</span>
          <Input
            style={{ width: 200 }}
            placeholder="판매 가격"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      </Modal>
    </>
  );
};

export default SpeedSearchResult;
