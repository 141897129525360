import { fetchMemberData } from '@/api/public';
import { memberAtom } from '@/store/member';
import { useSetAtom } from 'jotai';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const useFetchUserInfo = () => {
  const navigate = useNavigate();
  const setMemberDataAtom = useSetAtom(memberAtom);

  const fetchUserInfo = async (token: string, isRedirect = true) => {
    if (token) {
      localStorage.setItem('token', token);
      try {
        const decodedToken: Token = jwtDecode(token);
        const parse = JSON.parse(decodedToken.details.toString());

        if (parse) {
          const response = await fetchMemberData(parse['memberId']);
          setMemberDataAtom(response.data);
          if (!!isRedirect) {
            navigateToFirstMenu(response.data.adminMenu, navigate);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const navigateToFirstMenu = (adminMenu: any[], navigate: (path: string) => void) => {
    if (!adminMenu || adminMenu.length === 0) {
      navigate('/');
      return;
    }

    const firstMenu = adminMenu[0]?.routeUrl || adminMenu[0]?.children?.[0]?.routeUrl;

    if (firstMenu) {
      navigate(firstMenu);
    } else {
      navigate('/');
    }
  };

  return fetchUserInfo;
};

export default useFetchUserInfo;
