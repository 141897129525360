import { patchBannerSort } from '@/api/public';
import { Button, Table, TableColumnsType, message } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { HolderOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface DataType {
  key: React.Key;
  id: number;
  index: number;
  type: EnumPresenter;
  bannerLocation: EnumPresenter;
  contentsType: EnumPresenter;
  contents: string;
  fileName: string;
  link: string;
  display: boolean;
  sort: number;
  createdDate: string;
}

const SearchResult = ({ selectedFilterValue, data, getBannerData }: BannerResponseDataProps) => {
  const navigate = useNavigate();

  interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
  }

  const RowContext = React.createContext<RowContextProps>({});

  const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        size="small"
        icon={<HolderOutlined />}
        style={{ cursor: 'move' }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const columns: TableColumnsType<DataType> = [
    { key: 'sort', align: 'center', width: 80, render: () => <DragHandle /> },
    { title: '노출순서', dataIndex: 'sort' },
    {
      title: '배너타입',
      dataIndex: 'type',
      render: (text, record) => record.type.desc,
    },
    {
      title: '배너 노출 위치',
      dataIndex: 'bannerLocation',
      render: (text, record) => record.bannerLocation.desc,
    },
    {
      title: '배너 내용 타입',
      dataIndex: 'contentsType',
      render: (text, record) => record.contentsType.desc,
    },
    {
      title: '내용',
      dataIndex: 'contents',
      render: (text, record) => (
        <>
          <p
            className="mr-3 overflow-hidden break-all whitespace-no-wrap text-ellipsis line-clamp-1"
            style={{
              maxWidth: '300px',
            }}
          >
            {record.contents}
          </p>
        </>
      ),
    },
    { title: '이동링크', dataIndex: 'link' },
    {
      title: '노출여부',
      dataIndex: 'display',
      render: (text, record) => (record.display ? '노출' : '미노출'),
    },

    { title: '등록일자', dataIndex: 'createdDate' },
  ];

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (selectedFilterValue.page - 1) * 10 + index + 1,
      type: item.type,
      bannerLocation: item.bannerLocation,
      contentsType: item.contentsType,
      contents: item.contents,
      fileName: item.fileName,
      link: item.link,
      display: item.display,
      sort: item.sort,
      createdDate: item.createdDate,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const patchBannerSortMutation = useMutation(
    (requestData: { bannerId: string; sortNumber: string }) => patchBannerSort(requestData),
    {
      onSuccess: ({ data }) => {
        message.success('노출 순서가 변경되었어요', 2);
        getBannerData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const moveSort = (id: number, sort: number) => {
    const request = {
      bannerId: id?.toString(),
      sortNumber: sort?.toString(),
    };
    patchBannerSortMutation.mutate(request);
  };

  const onMoveRegisterPage = () => {
    navigate(`/banner/form`);
  };

  const rowProps = (record: DataType) => ({
    onClick: () => {
      navigate(`/banner/${record.id}`);
    },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setTableData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.key === active?.id);
        const overIndex = prevState.findIndex((record) => record.key === over?.id);
        const activeData = prevState.find((record) => record.key === active?.id);
        const overData = prevState.find((record) => record.key === over?.id);

        if (activeData !== undefined && overData !== undefined) {
          moveSort(activeData.id, overData.sort);
        }

        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
  }

  const Row: React.FC<RowProps> = (props) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(
      () => ({ setActivatorNodeRef, listeners }),
      [setActivatorNodeRef, listeners],
    );

    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 배너</span> {data?.totalElements}개
          </div>
          <div>
            <Button className="mr-2" onClick={onMoveRegisterPage} style={{ borderColor: '#1890ff', color: '#1890ff' }}>
              등록
            </Button>
          </div>
        </div>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext items={tableData.map((i) => i.key) as []} strategy={verticalListSortingStrategy}>
            <Table
              rowKey="key"
              components={{ body: { row: Row } }}
              columns={columns}
              dataSource={tableData}
              pagination={false}
              onRow={rowProps}
            />
          </SortableContext>
        </DndContext>
      </div>
    </>
  );
};

export default SearchResult;
