import { patchProductInquiry, patchProductInquiryComment, patchProductInquiryStatusChange } from '@/api/public';
import { productInquiryEnumAtom } from '@/store/productInquiry';
import { productsEnumAtom } from '@/store/products';
import { convertToSalesPeopleOptions, convertToSelectOptions } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BasicPopup from '../Common/BasicPopup';
import SortingButton from '../Common/SortingButton';

interface ProductInquiryResponseDataProps {
  data: ProductInquiryResponse | null;
  currentPage: number;
  getProductInquiryData: () => void;
  ascending: boolean;
  updateAscending: () => void;
}

interface StatusChangeRequest {
  id: string;
  status: string;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  status?: EnumPresenter;
  name?: string;
  requestPhoneNumber?: string;
  productsNumber?: number;
  minYear?: string;
  maxYear?: string;
  minTons?: string;
  maxTons?: string;
  loaded: EnumPresenter;
  minLoadedInnerLength?: string;
  maxLoadedInnerLength?: string;
  axis?: EnumPresenter;
  minDistance?: string;
  maxDistance?: string;
  transmission?: EnumPresenter;
  comment?: string;
  createdDate?: string;
  product?: ProductDetail;
  salesPeople?: SalesPeople;
}

const ProductInquirySearchResult = ({
  data,
  currentPage,
  getProductInquiryData,
  ascending,
  updateAscending,
}: ProductInquiryResponseDataProps) => {
  const navigate = useNavigate();
  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '요청자',
      dataIndex: 'name',
    },
    {
      title: '요청 전화번호',
      dataIndex: 'requestPhoneNumber',
    },
    {
      title: '상태',
      dataIndex: 'status',
      render: (text, record) => (
        <Select
          value={record.status?.desc}
          options={selectOptions.status}
          style={{ width: 90 }}
          onChange={(value) => handleStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: '차량 제시번호',
      dataIndex: 'productsNumber',
      render: (text, record) =>
        record.productsNumber && (
          <Button onClick={() => navigate(`/vehicles/all/${record.product?.id}`)}>{record.productsNumber}</Button>
        ),
    },
    {
      title: '최소연식',
      dataIndex: 'minYear',
    },
    {
      title: '최대연식',
      dataIndex: 'maxYear',
    },
    {
      title: '최소톤수',
      dataIndex: 'minTons',
    },
    {
      title: '최대톤수',
      dataIndex: 'maxTons',
    },
    {
      title: '적재함 종류',
      dataIndex: 'loaded',
      render: (text, record) => record.loaded?.desc || '-',
    },
    {
      title: '최소적재함길이',
      dataIndex: 'minLoadedInnerLength',
    },
    {
      title: '최대적재함길이',
      dataIndex: 'maxLoadedInnerLength',
    },
    {
      title: '가변축',
      dataIndex: 'axis',
      render: (text, record) => record.axis?.desc || '-',
    },
    {
      title: '최소주행거리',
      dataIndex: 'minDistance',
    },
    {
      title: '최대주행거리',
      dataIndex: 'maxDistance',
    },
    {
      title: '변속기',
      dataIndex: 'transmission',
      render: (text, record) => record.transmission?.desc || '-',
    },
    {
      title: <SortingButton title="요청일자" isAscending={ascending} onclick={updateAscending} />,
      dataIndex: 'createdDate',
    },
    {
      title: '담당자',
      dataIndex: 'salesPeople',
      render: (text, record) => (
        <Select
          value={record.salesPeople?.name}
          options={selectOptions.salesPeople}
          style={{ width: 100 }}
          onChange={(value) => handleSalesPeopleChange(Number(value), record)}
          onClick={(e) => e.stopPropagation()}
        ></Select>
      ),
    },
    {
      title: '메모',
      dataIndex: 'memo',
      render: (text, record) => (
        <>
          {record.comment ? (
            <>
              <p
                className="mr-3 overflow-hidden font-bold break-all whitespace-no-wrap text-ellipsis"
                style={{
                  maxWidth: '500px',
                  maxHeight: '200px',
                }}
              >
                {record.comment}
              </p>

              <Button onClick={() => handleMemoButtonClick(record)}>수정</Button>
            </>
          ) : (
            <Button onClick={() => handleMemoButtonClick(record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const handleMemoButtonClick = (productInquiryInfo: any) => {
    setIsMemoModalVisible(true);
    setSelectedMemoId(productInquiryInfo.id);
    setIsDisabledEditBtn(true);
    setMemoContent(productInquiryInfo.comment);
  };

  const convertProductInquiryFilterInfoToSelectOptions = (
    productInquiryFilterInfo: ProductInquiryFilterInfoType,
    productFilterEnum: ProductsFilterInfoType,
  ) => {
    return {
      status: convertToSelectOptions(productInquiryFilterInfo.status),
      salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
    };
  };

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [statusRequest, setStatusRequest] = useState<StatusChangeRequest>({ id: '', status: '' });
  const [productInquiryFilterInfo] = useAtom(productInquiryEnumAtom);
  const [productFilterEnum] = useAtom(productsEnumAtom);
  const selectOptions = convertProductInquiryFilterInfoToSelectOptions(productInquiryFilterInfo, productFilterEnum);
  const [isMemoModalVisible, setIsMemoModalVisible] = useState(false);
  const [selectedMemoId, setSelectedMemoId] = useState(0);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);
  const [memoContent, setMemoContent] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [basicPopupData, setBasicPopupData] = useState({
    title: '',
    handleOk: () => {},
    handleCancel: () => {},
  });

  const handleStatusChange = (value: string, record: DataType) => {
    setIsOpenPopup(true);
    const request = {
      id: String(record.id),
      status: value,
    };

    setBasicPopupData({
      title: '상태 변경하시겠어요?',
      handleOk: () => onClickStatusChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenPopup(true);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;
  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSalesPeopleChange = (value: number, record: DataType) => {
    console.log(record);
    const request = {
      id: record.id,
      salesPeopleId: value,
    };
    setBasicPopupData({
      title: '담당자를 변경하시겠어요?',
      handleOk: () => onClickSalesPeopleChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenPopup(true);
  };

  const onClickSalesPeopleChangeButton = (requestData: { id: number; salesPeopleId: number }) => {
    patchProductInquirySalesPeopleMutation.mutate(requestData);
  };

  const patchProductInquirySalesPeopleMutation = useMutation(
    (requestData: { id: number; salesPeopleId: number }) => patchProductInquiry(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('수정되었습니다.', 2);
        setIsDisabledEditBtn(true);
        getProductInquiryData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsOpenPopup(false);
      },
    },
  );

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      status: item.status || null,
      name: item.name || '-',
      requestPhoneNumber: item.requestPhoneNumber || '-',
      minYear: item.minYear || '-',
      maxYear: item.maxYear || '-',
      minTons: item.minTons || '-',
      maxTons: item.maxTons || '-',
      loaded: item.loaded || null,
      minLoadedInnerLength: item.minLoadedInnerLength || '-',
      maxLoadedInnerLength: item.maxLoadedInnerLength || '-',
      axis: item.axis || null,
      minDistance: item.minDistance || '-',
      maxDistance: item.maxDistance || '-',
      transmission: item.transmission || '-',
      comment: item.comment || '',
      createdDate: item.createdDate || '-',
      productsNumber: item.product?.productsNumber,
      product: item.product,
      salesPeople: item.salesPeople,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const handleMemoModalClose = () => {
    setIsMemoModalVisible(false);
    setMemoContent('');
  };

  const handleMemoSave = () => {
    handleMemoModalClose();
    const request = {
      comment: memoContent,
    };
    patchProductInquiryCommentMutation.mutate(request);
  };

  const patchProductInquiryCommentMutation = useMutation(
    (requestData: { comment: string }) => patchProductInquiryComment(String(selectedMemoId), requestData),
    {
      onSuccess: () => {
        message.success('상세 정보가 수정되었습니다.', 2);
        setIsDisabledEditBtn(true);
        getProductInquiryData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const onChangeMemo = (memo: string) => {
    setMemoContent(memo);
    setIsDisabledEditBtn(false);
  };

  const patchProductInquiryCompletedMutation = useMutation(
    (requestData: { id: string; status: string }) => patchProductInquiryStatusChange(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('상태가 변경되었습니다.', 2);

        getProductInquiryData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsOpenPopup(false);
        setStatusRequest({ id: '', status: '' });
      },
    },
  );

  const onClickStatusChangeButton = (requestData: { id: string; status: string }) => {
    patchProductInquiryCompletedMutation.mutate(requestData);
  };
  const onClickCancelButton = () => {
    setIsOpenPopup(false);
    setStatusRequest({ id: '', status: '' });
  };
  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 요청건수</span> {data?.totalElements}개
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={false} />
      </div>

      <Modal
        title="메모 보기"
        open={isMemoModalVisible}
        onCancel={handleMemoModalClose}
        footer={[
          <Button key="close" onClick={handleMemoModalClose}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleMemoSave}
            disabled={isDisabledEditBtn}
          >
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={memoContent}
          onChange={(e) => onChangeMemo(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={() => basicPopupData.handleOk()}
        handleCancel={() => basicPopupData.handleCancel()}
      ></BasicPopup>
    </>
  );
};

export default ProductInquirySearchResult;
