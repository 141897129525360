import { deleteAdminRole } from '@/api/public';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, message, Table, TableColumnsType } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import React, { memo, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BasicPopup from '../Common/BasicPopup';

interface AdminRoleDataType {
  id: number;
  key: React.Key;
  index: number;
  name: string;
  createdDate: string;
  isDelete: boolean;
}

const AdminRoleList = memo(
  ({ data, currentPage, getData, filterValue }: SearchResultListProps<AdminRole, AdminRoleFilter>) => {
    const navigate = useNavigate();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);

    const columns: TableColumnsType<AdminRoleDataType> = [
      { title: '번호', dataIndex: 'index' },
      { title: '역할명', dataIndex: 'name' },
      { title: '생성일자', dataIndex: 'createdDate' },
      {
        title: '삭제여부',
        dataIndex: 'isDelete',
        render: (text, record) => <>{record.isDelete ? '삭제' : '미삭제'}</>,
      },
    ];

    const tableData = useMemo(() => {
      return (
        data?.data?.map((item, index) => ({
          id: item.id,
          key: item.id,
          index: (currentPage - 1) * 10 + index + 1,
          name: item.name || '-',
          createdDate: item.createdDate || '-',
          isDelete: item.isDelete || false,
        })) || []
      );
    }, [data, currentPage]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<AdminRoleDataType> = {
      type: 'radio',
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const rowProps = (record: AdminRoleDataType) => ({
      onClick: () => {
        navigate(`/admin-role/${record.id}`);
      },
    });

    const showDeleteConfirm = () => {
      setIsOpenDeletePopup(true);
    };

    const onClickDelete = () => {
      setIsOpenDeletePopup(false);

      if (selectedRowKeys.length > 0) {
        const [firstSelectedId] = selectedRowKeys;
        deleteAdminRoleMutation.mutate(String(firstSelectedId));
      } else {
        message.error('역할이 선택되지 않았어요. 삭제할 역할을 선택해주세요.');
      }
    };
    const deleteAdminRoleMutation = useMutation(deleteAdminRole, {
      onSuccess: () => {
        message.success('삭제되었습니다.', 2);
        setSelectedRowKeys([]);
        getData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    });

    const handleClickRegister = () => {
      navigate('/admin-role/form');
    };

    return (
      <>
        <div className="flex justify-end w-full mb-2">
          <Button
            className="border border-blue-500 text-blue-500 hover:!border-blue-500 hover:!text-blue-500 mr-2"
            onClick={handleClickRegister}
          >
            등록
          </Button>
          <Button
            className="border border-red-500 text-red-500 hover:!border-red-500 hover:!text-red-500"
            onClick={showDeleteConfirm}
          >
            삭제
          </Button>
        </div>
        <div className="py-4">
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            onRow={rowProps}
          />
        </div>
        <BasicPopup
          isOpen={isOpenDeletePopup}
          setIsOpen={setIsOpenDeletePopup}
          icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
          title="정말 삭제하시겠어요?"
          content=""
          handleOk={() => onClickDelete()}
          handleCancel={() => setIsOpenDeletePopup(false)}
        ></BasicPopup>
      </>
    );
  },
);
export default AdminRoleList;
