import { Button, DatePicker, Input, InputNumber, Select } from 'antd';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';

import { ALL, ASSURANCE, CONSIGNMENT, DEFAULT_FILTER_STATUSES, NORMAL, THEUNBAN } from '@/const/vehicles';
import { productPurchaseManageEnumAtom } from '@/store/productPurchaseManage';
import { productsEnumAtom } from '@/store/products';
import { convertToSelectOptions } from '@/utils/common';
import dayjs from 'dayjs';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface SearchProps {
  salesType: string;
  selectedFilterValue: SelectedFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedFilterValue>>;
  getTruckData?: () => void;
}

const VehicleFilterSelector = ({ salesType, selectedFilterValue, setSelectedFilterValue }: SearchProps) => {
  const [productFilterInfo] = useAtom(productsEnumAtom);
  const [productPurchaseManageFilterEnum] = useAtom(productPurchaseManageEnumAtom);

  const convertManufacturerCategoriesToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
  ): { value: string; label: string }[] => {
    return manufacturerCategoriesWithModels.map((item) => ({
      value: item.manufacturerCategories.id.toString(),
      label: item.manufacturerCategories.name,
    }));
  };

  const convertModelsToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
    selectedManufacturerCategoryId: string,
  ): { value: string; label: string }[] => {
    const selectedManufacturerCategory = manufacturerCategoriesWithModels.find(
      (item) => item.manufacturerCategories.id.toString() === selectedManufacturerCategoryId,
    );

    return selectedManufacturerCategory
      ? selectedManufacturerCategory.model.map((model) => ({
          value: model.id.toString(),
          label: model.name,
        }))
      : [];
  };

  const convertProductsFilterInfoToSelectOptions = (productsFilterInfo: ProductsFilterInfoType) => {
    return {
      axis: convertToSelectOptions(productsFilterInfo.axis),
      loaded: convertToSelectOptions(productsFilterInfo.loaded),
      manufacturerCategories: convertManufacturerCategoriesToSelectOptions(
        productsFilterInfo.manufacturerCategoriesWithModels,
      ),
      model: convertModelsToSelectOptions(
        productsFilterInfo.manufacturerCategoriesWithModels,
        selectedManufacturerCategoryId,
      ),
      productsType: convertToSelectOptions(productsFilterInfo.productsType),
      salesType: convertToSelectOptions(productsFilterInfo.salesType),
      status: convertToSelectOptions(productsFilterInfo.status),
      transmission: convertToSelectOptions(productsFilterInfo.transmission),
      locate: convertToSelectOptions(productsFilterInfo.locate),
      fuel: convertToSelectOptions(productsFilterInfo.fuel),
      isDelete: [
        { value: '', label: '전체' },
        { value: 'true', label: '삭제' },
        { value: 'false', label: '미삭제' },
      ],
      productPurchaseManageStatus: convertToSelectOptions(productPurchaseManageFilterEnum.status),
      isInvoiceSendTheunban: [
        { value: '', label: '전체' },
        { value: 'true', label: '전송 완료' },
        { value: 'false', label: '미전송' },
      ],
      externalClientsName: [
        { value: '', label: '전체' },
        { value: 'THE_UNBAN', label: '더운반' },
      ],
    };
  };

  const [selectedManufacturerCategoryId, setSelectedManufacturerCategoryId] = useState<string>('');

  const convertModelsOptions = convertModelsToSelectOptions(
    productFilterInfo.manufacturerCategoriesWithModels,
    selectedManufacturerCategoryId,
  );

  const selectOptions = convertProductsFilterInfoToSelectOptions(productFilterInfo);

  useEffect(() => {
    setSelectedManufacturerCategoryId(selectedFilterValue.manufacturerCategories || '');
  }, [selectedFilterValue.manufacturerCategories]);

  const onChangeMultipleSelect = (name: string, value: string[]) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const onChangeSelect = (name: string, value: string) => {
    if (name === 'manufacturerCategories') {
      setSelectedManufacturerCategoryId(value);
      setSelectedFilterValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
        model: null,
      }));
    } else {
      setSelectedFilterValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
      }));
    }
  };

  const handleInputNumberChange = (name: string, value: number | null) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value || '',
    }));
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      sellerKeyword: null,
      productNumber: null,
      truckName: null,
      truckNumber: null,
      status: DEFAULT_FILTER_STATUSES,
      productsType: null,
      salesType: null,
      manufacturerCategories: null,
      model: null,
      tons: null,
      loaded: null,
      loadedInnerLength: null,
      axis: null,
      transmission: null,
      isDelete: '',
      externalClientsName: '',
      isInvoiceSendTheunban: '',
      startDate: null,
      endDate: null,
      page: 1,
    });
    switch (salesType) {
      case ALL:
        sessionStorage.removeItem('vehiclesListParam');
        break;
      case ASSURANCE:
        sessionStorage.removeItem('assuranceListParam');
        break;
      case CONSIGNMENT:
        sessionStorage.removeItem('consignmentListParam');
        break;
      case THEUNBAN:
        sessionStorage.removeItem('theunbanListParam');
        break;
      case NORMAL:
        sessionStorage.removeItem('directListParam');
        break;
    }
  };

  return (
    <div className="py-4">
      <div className="flex flex-wrap gap-4 mb-4">
        {(salesType === ALL || salesType === THEUNBAN || salesType === NORMAL) && (
          <Search
            placeholder="판매자 정보(번호/이름)"
            allowClear
            style={{ width: 200 }}
            value={selectedFilterValue?.sellerKeyword || ''}
            onChange={(e) => handleInputStringChange('sellerKeyword', e.target.value)}
          />
        )}
        <InputNumber
          placeholder="제시번호 입력"
          style={{ width: 180 }}
          value={selectedFilterValue?.productNumber}
          onChange={(value) => handleInputNumberChange('productNumber', value)}
        />
        <Search
          placeholder="차량명 입력"
          allowClear
          style={{ width: 180 }}
          value={selectedFilterValue?.truckName || ''}
          onChange={(e) => handleInputStringChange('truckName', e.target.value)}
        />
        <Search
          placeholder="차량번호 입력"
          allowClear
          style={{ width: 180 }}
          value={selectedFilterValue?.truckNumber || ''}
          onChange={(e) => handleInputStringChange('truckNumber', e.target.value)}
        />
        <Select
          mode="multiple"
          style={{ width: 350 }}
          placeholder="매물 상태"
          options={selectOptions.status}
          value={selectedFilterValue.status}
          onChange={(value) => onChangeMultipleSelect('status', value)}
        />
        {salesType === ALL && (
          <Select
            style={{ width: 100 }}
            placeholder="매물 유형"
            optionFilterProp="children"
            options={selectOptions.productsType}
            value={selectedFilterValue.productsType}
            onChange={(value) => onChangeSelect('productsType', value)}
          />
        )}
        {(salesType === ALL || salesType === THEUNBAN) && (
          <Select
            style={{ width: 130 }}
            placeholder="판매 유형"
            optionFilterProp="children"
            options={selectOptions.salesType}
            value={selectedFilterValue.salesType}
            onChange={(value) => onChangeSelect('salesType', value)}
          />
        )}
        {salesType == THEUNBAN && (
          <Select
            style={{ width: 130 }}
            placeholder="구매관리 상태"
            options={selectOptions.productPurchaseManageStatus}
            value={selectedFilterValue.productPurchaseManageStatus}
            onChange={(value) => onChangeSelect('productPurchaseManageStatus', value)}
          />
        )}
        <Select
          style={{ width: 100 }}
          placeholder="삭제여부"
          optionFilterProp="children"
          options={selectOptions.isDelete || ''}
          value={selectedFilterValue.isDelete}
          onChange={(value) => onChangeSelect('isDelete', value)}
        />
        {(salesType === ASSURANCE || salesType === CONSIGNMENT || salesType === NORMAL) && (
          <Select
            style={{ width: 120 }}
            placeholder="외부 고객사"
            optionFilterProp="children"
            options={selectOptions.externalClientsName || ''}
            value={selectedFilterValue.externalClientsName}
            onChange={(value) => onChangeSelect('externalClientsName', value)}
          />
        )}
      </div>
      <div className="flex flex-wrap gap-4 mb-4">
        <Select
          style={{ width: 200 }}
          placeholder="제조사"
          optionFilterProp="children"
          options={selectOptions.manufacturerCategories}
          value={selectedFilterValue.manufacturerCategories}
          onChange={(value) => onChangeSelect('manufacturerCategories', value)}
        />
        <Select
          style={{ width: 200 }}
          placeholder="모델"
          optionFilterProp="children"
          options={convertModelsOptions}
          value={selectedFilterValue.model}
          onChange={(value) => onChangeSelect('model', value)}
        />
        <InputNumber
          placeholder="톤수"
          style={{ width: 100 }}
          value={selectedFilterValue?.tons}
          onChange={(value) => handleInputNumberChange('tons', value)}
          suffix="t"
        />
        <Select
          style={{ width: 200 }}
          placeholder="적재함 종류"
          optionFilterProp="children"
          options={selectOptions.loaded}
          value={selectedFilterValue.loaded}
          onChange={(value) => onChangeSelect('loaded', value)}
        />
        <InputNumber
          placeholder="적재함 길이"
          style={{ width: 180 }}
          value={selectedFilterValue?.loadedInnerLength}
          onChange={(value) => handleInputNumberChange('loadedInnerLength', value)}
          suffix="m"
        />
        <Select
          style={{ width: 200 }}
          placeholder="가변축"
          optionFilterProp="children"
          options={selectOptions.axis}
          value={selectedFilterValue.axis}
          onChange={(value) => onChangeSelect('axis', value)}
        />
        {salesType !== THEUNBAN && (
          <Select
            style={{ width: 200 }}
            placeholder="변속기"
            optionFilterProp="children"
            options={selectOptions.transmission}
            value={selectedFilterValue?.transmission}
            onChange={(value) => onChangeSelect('transmission', value)}
          />
        )}
        {salesType == THEUNBAN && (
          <Select
            style={{ width: 200 }}
            placeholder="더운반 파일 전송 여부"
            optionFilterProp="children"
            options={selectOptions.isInvoiceSendTheunban}
            value={selectedFilterValue.isInvoiceSendTheunban}
            onChange={(value) => onChangeSelect('isInvoiceSendTheunban', value)}
          />
        )}
      </div>
      <div className="flex w-full">
        <div className="w-full">
          <span className="mr-2">등록일자</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
              selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>

        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default VehicleFilterSelector;
