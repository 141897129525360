import {
  convertToSalesPeopleOptions,
  convertToSelectOptions,
  formatNumberWithComma,
  getFileName,
  getFormatDate,
} from '@/utils/common';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import React, { useEffect, useState } from 'react';

import { deleteProduct, patchProductsDetail, postProductInvoicesForTheunban } from '@/api/public';
import BasicPopup from '@/components/Common/BasicPopup';
import ExcelDownloadButton from '@/components/Common/Button/ExcelDownloadButton';
import SortingButton from '@/components/Common/SortingButton';
import { productPurchaseManageEnumAtom } from '@/store/productPurchaseManage';
import { productsEnumAtom } from '@/store/products';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface StatusChangeRequest {
  id: string;
  productPurchaseManageStatus: string;
}

const TheunbanSearchResult = ({
  selectedFilterValue,
  data,
  getTruckData,
  ascending,
  setAscending,
}: ProductResponseDataProps) => {
  const onclickSortingButton = () => {
    if (setAscending) {
      setAscending(!ascending);
    }
  };
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPoppup, setIsOpenPopup] = useState(false);
  const [excelDownloadParams, setExcelDownloadParams] = useState<ProductExcelParams>({});
  const [isManageMemoModalOpen, setIsManageMemoModalOpen] = useState(false);
  const [purchaseManageComment, setPurchaseManageComment] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [productPurchaseManageFilterEnum] = useAtom(productPurchaseManageEnumAtom);
  const [basicPopupData, setBasicPopupData] = useState({
    title: '',
    handleOk: () => {},
    handleCancel: () => {},
  });

  const [productFilterEnum] = useAtom(productsEnumAtom);
  const [isOpenSalesPeopleChangePopup, setIsOpenSalesPeopleChangePopup] = useState(false);

  const convertProductsPurchaseManageFilterInfoToSelectOptions = (
    productPurchaseManageFilterEnum: ProductPurchaseManageFilterInfoType,
    productFilterEnum: ProductsFilterInfoType,
  ) => {
    return {
      productPurchaseManageStatus: convertToSelectOptions(productPurchaseManageFilterEnum.status),
      salesPeople: convertToSalesPeopleOptions(productFilterEnum.salesPeople),
    };
  };
  const selectOptions = convertProductsPurchaseManageFilterInfoToSelectOptions(
    productPurchaseManageFilterEnum,
    productFilterEnum,
  );

  const onChangeSelect = (value: string, record: ProductListDataType) => {
    const request = {
      id: String(record.id),
      salesPeopleId: value,
    };
    setBasicPopupData({
      title: '담당자를 변경하시겠어요?',
      handleOk: () => onClickSalesPeopleChangeButton(request),
      handleCancel: () => onClickCancelButton(),
    });
    setIsOpenSalesPeopleChangePopup(true);
  };
  const onClickCancelButton = () => {
    setIsOpenSalesPeopleChangePopup(false);
  };

  const onClickSalesPeopleChangeButton = (requestData: { id: string; salesPeopleId: string }) => {
    patchSalesPeopleModifyMutation.mutate(requestData);
  };

  const patchSalesPeopleModifyMutation = useMutation(
    (requestData: { id: string; salesPeopleId: string }) => patchProductsDetail(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('담당자가 변경되었습니다.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsOpenSalesPeopleChangePopup(false);
      },
    },
  );

  const columns: TableColumnsType<ProductListDataType> = [
    {
      title: '제시번호',
      dataIndex: 'productsNumber',
    },
    {
      title: '차량명',
      dataIndex: 'truckName',
    },
    {
      title: '차량번호',
      dataIndex: 'truckNumber',
    },
    {
      title: '상태',
      dataIndex: 'status',
    },
    {
      title: '매물유형',
      dataIndex: 'type',
    },
    {
      title: '판매유형',
      dataIndex: 'salesType',
    },
    {
      title: '연식',
      dataIndex: 'firstRegistrationDate',
    },
    {
      title: '적재함 길이',
      dataIndex: 'loadedInnerLength',
    },
    {
      title: '판매가격',
      dataIndex: 'price',
    },
    {
      title: <SortingButton title="등록일자" isAscending={ascending} onclick={onclickSortingButton} />,
      dataIndex: 'createdDate',
    },
    {
      title: '영업 담당자',
      dataIndex: 'salesPeople',
      render: (text, record) => (
        <>
          <Select
            className="my-2 mr-4"
            style={{ width: 200 }}
            placeholder="배너 노출 위치"
            optionFilterProp="children"
            options={selectOptions.salesPeople}
            value={record?.salesPeople}
            onChange={(value) => onChangeSelect(value, record)}
            onClick={(e) => e.stopPropagation()}
          ></Select>
        </>
      ),
    },
    {
      title: '구매자',
      dataIndex: 'buyer',
    },
    {
      title: '삭제여부',
      dataIndex: 'isDelete',
      render: (text, record) => (record.isDelete == true ? '삭제' : '미삭제'),
    },
    {
      title: '관리상태',
      dataIndex: 'productPurchaseManage',
      render: (text, record) => (
        <Select
          value={record.productManage?.status?.desc}
          options={selectOptions.productPurchaseManageStatus}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(value, record)}
          onClick={(e) => e.stopPropagation()}
        ></Select>
      ),
    },
    {
      title: '관리메모',
      dataIndex: 'productPurchaseManage',
      render: (text, record) => (
        <>
          {record?.productManage?.comment ? (
            <>
              <p
                className="mr-3 overflow-hidden font-bold break-all whitespace-no-wrap text-ellipsis"
                style={{
                  maxWidth: '500px',
                  maxHeight: '200px',
                }}
              >
                {record?.productManage?.comment}
              </p>

              <Button onClick={(e) => onClickMemoBtn(e, record)}>수정</Button>
            </>
          ) : (
            <Button onClick={(e) => onClickMemoBtn(e, record)}>작성</Button>
          )}
        </>
      ),
    },
    {
      title: '매매계약서',
      dataIndex: 'saleInvoiceImageUrl',
      render: (text, record) => (
        <>
          <p
            className="mr-3 overflow-hidden break-all whitespace-no-wrap text-ellipsis line-clamp-1"
            style={{
              maxWidth: '300px',
            }}
          >
            {getFileName(record.productImages?.saleInvoiceImageUrl)}
          </p>
        </>
      ),
    },
    {
      title: '세금 계산서',
      dataIndex: 'taxImageUrl',
      render: (text, record) => (
        <>
          <p
            className="mr-3 overflow-hidden break-all whitespace-no-wrap text-ellipsis line-clamp-1"
            style={{
              maxWidth: '300px',
            }}
          >
            {getFileName(record.productImages?.taxImageUrl)}
          </p>
        </>
      ),
    },
    {
      title: '더운반 파일 전송',
      dataIndex: 'sendInvoiceFile',
      render: (text, record) =>
        record.productImages?.saleInvoiceImageUrl &&
        record.productImages?.taxImageUrl && (
          <Button onClick={(e) => onClickProductInvoicesSendBtn(e, record)}>
            {record.productImages?.isSend ? '재전송' : '전송'}
          </Button>
        ),
    },
  ];

  const onClickMemoBtn = (e: React.MouseEvent<HTMLElement, MouseEvent>, data: ProductListDataType) => {
    setPurchaseManageComment(data.productManage?.comment);
    setSelectedProductId(String(data.id));
    setIsManageMemoModalOpen(true);
    e.stopPropagation();
  };

  const postProductInvoicesSendMutation = useMutation(postProductInvoicesForTheunban, {
    onSuccess: () => {
      message.success('더운반에 파일이 전송되었습니다.', 2);
      getTruckData();
    },
    onError: (error: AxiosError) => {
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    },
  });

  const onClickProductInvoicesSendBtn = (e: React.MouseEvent<HTMLElement, MouseEvent>, data: ProductListDataType) => {
    if (!data?.productImages?.saleInvoiceImageUrl) {
      message.error('매매계약서를 업로드해주세요.', 2);
      return;
    }

    if (!data?.productImages?.saleInvoiceImageUrl) {
      message.error('세금계산서를 업로드해주세요.', 2);
      return;
    }

    postProductInvoicesSendMutation.mutate({
      productId: data.id,
      saleInvoiceImageUrl: data.productImages.saleInvoiceImageUrl,
      taxImageUrl: data.productImages.taxImageUrl,
    });
    e.stopPropagation();
  };

  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      productsNumber: item.productsNumber,
      truckName: item.truckName,
      truckNumber: item.truckNumber,
      type: item.type?.desc || '',
      status: item.status?.desc || '',
      manufacturerName: item.manufacturerName,
      modelName: item.modelName,
      firstRegistrationDate: getFormatDate(String(item.firstRegistrationDate), ['year', 'month'], true),
      loadedInnerLength: item.loadedInnerLength,
      transmission: item.transmission?.desc || '',
      distance: formatNumberWithComma(String(item.distance)),
      price: item.price,
      createdDate: item.createdDate,
      salesPeople: item.salesPeople?.id ? `${item.salesPeople?.name}(${item.salesPeople?.phoneNumber})` : '-',
      buyer: item.buyer?.id ? `${item.buyer?.name}(${item.buyer?.phoneNumber})` : '-',
      productManage: item.productPurchaseManage,
      isDelete: item.isDelete,
      salesType: item.salesType?.desc || '',
      saleInvoiceImageUrl: item.productImages.saleInvoiceImageUrl,
      taxImageUrl: item.productImages.taxImageUrl,
      isTheUnbanProduct: item.isTheUnbanProduct,
      productImages: item.productImages,
    })) || [];

  const [tableData, setTableData] = useState<ProductListDataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowProps = (record: ProductListDataType) => ({
    onClick: () => {
      navigate(`/vehicles/theunban/${record.id}`);
    },
  });

  const getProductPurchaseManageStatus = (code: string): string => {
    const result = productPurchaseManageFilterEnum.status.find((item: EnumPresenter) => item.code === code) as
      | EnumPresenter
      | undefined;
    return result ? result.desc : '';
  };

  const handleStatusChange = (value: string, record: ProductListDataType) => {
    const request = {
      id: String(record.id),
      productPurchaseManageStatus: value,
    };

    setBasicPopupData({
      title: getProductPurchaseManageStatus(value) + ' 상태로 변경하시겠습니까?',
      handleOk: () => onClickStatusChangeButton(request),
      handleCancel: () => setIsOpenPopup(false),
    });
    setIsOpenPopup(true);
  };

  const rowSelection: TableRowSelection<ProductListDataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onClickDeleteButton = () => {
    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      mutate(String(firstSelectedId));
    } else {
      message.error('차량이 선택되지 않았어요. 삭제할 차량을 선택해주세요.');
    }
    setIsOpenPopup(false);
  };

  const onClickDelete = () => {
    setIsOpenPopup(false);
    setBasicPopupData({
      title: '정말 삭제하시겠어요?',
      handleOk: () => onClickDeleteButton(),
      handleCancel: () => setIsOpenPopup(false),
    });
  };

  const { mutate } = useMutation(deleteProduct, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getTruckData();
    },
    onError: (error: AxiosError) => {
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    },
  });

  const hasSelected = selectedRowKeys.length > 0;

  const showConfirm = () => {
    onClickDelete();
    setIsOpenPopup(true);
  };

  const handleManageModalClose = () => {
    setIsManageMemoModalOpen(false);
  };

  const patchPurchaseManageCommentMutation = useMutation(
    (requestData: { id: string; productPurchaseManageComment: string }) =>
      patchProductsDetail(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('관리메모가 작성되었어요.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const handleManageSave = () => {
    if (selectedProductId) {
      const request: any = {};
      request.id = selectedProductId;
      request.productPurchaseManageComment = purchaseManageComment;
      patchPurchaseManageCommentMutation.mutate(request);
    }
    setIsManageMemoModalOpen(false);
  };

  const onChangeComment = (comment: string) => {
    setPurchaseManageComment(comment);
  };

  useEffect(() => {
    setExcelDownloadParams({
      sellerKeyword: selectedFilterValue?.sellerKeyword ? selectedFilterValue.sellerKeyword : '',
      productNumber: selectedFilterValue?.productNumber ? String(selectedFilterValue?.productNumber) : '',
      truckName: selectedFilterValue?.truckName || '',
      truckNumber: selectedFilterValue?.truckNumber || '',
      productsStatus: selectedFilterValue?.status?.join(',') || '',
      productPurchaseManageStatus: selectedFilterValue?.productPurchaseManageStatus || '',
      type: selectedFilterValue?.productsType || '',
      manufacturerId: selectedFilterValue?.manufacturerCategories || '',
      modelId: selectedFilterValue?.model || '',
      tons: selectedFilterValue?.tons ? String(selectedFilterValue?.tons) : '',
      loaded: selectedFilterValue?.loaded || '',
      loadedInnerLength: selectedFilterValue?.loadedInnerLength ? String(selectedFilterValue?.loadedInnerLength) : '',
      axis: selectedFilterValue?.axis || '',
      startDate: selectedFilterValue?.startDate || '',
      endDate: selectedFilterValue?.endDate || '',
      salesType: selectedFilterValue?.salesType || '',
      isDelete: selectedFilterValue?.isDelete || '',
      isInvoiceSendTheunban: selectedFilterValue?.isInvoiceSendTheunban || '',
    });
  }, [selectedFilterValue]);

  const patchPurchaseManageStatusMutation = useMutation(
    (requestData: { id: string; productPurchaseManageStatus: string }) =>
      patchProductsDetail(String(requestData.id), requestData),
    {
      onSuccess: () => {
        message.success('상세 정보가 수정되었습니다.', 2);
        getTruckData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
    },
  );

  const onClickStatusChangeButton = (request: StatusChangeRequest) => {
    patchPurchaseManageStatusMutation.mutate(request);
    setIsOpenPopup(false);
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체차량</span> {data?.totalElements}대
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
          <div>
            <ExcelDownloadButton
              apiPath="/admin/v1/products/theunban/excel"
              params={excelDownloadParams}
              buttonText="엑셀 다운로드"
            ></ExcelDownloadButton>
            <Button danger onClick={showConfirm}>
              삭제
            </Button>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          onRow={rowProps}
          pagination={false}
        />
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={basicPopupData.handleOk}
        handleCancel={basicPopupData.handleCancel}
      ></BasicPopup>
      <Modal
        title="메모 보기"
        open={isManageMemoModalOpen}
        onCancel={handleManageModalClose}
        footer={[
          <Button key="close" onClick={handleManageModalClose}>
            닫기
          </Button>,
          <Button key="save" type="primary" className="bg-[#1890ff] mx-2" onClick={handleManageSave}>
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={purchaseManageComment}
          onChange={(e) => onChangeComment(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>

      <BasicPopup
        isOpen={isOpenSalesPeopleChangePopup}
        setIsOpen={setIsOpenSalesPeopleChangePopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title={basicPopupData.title}
        content=""
        handleOk={() => basicPopupData.handleOk()}
        handleCancel={() => basicPopupData.handleCancel()}
      ></BasicPopup>
    </>
  );
};

export default TheunbanSearchResult;
