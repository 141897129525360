import { getAdminMemberList } from '@/api/public';
import AdminMemberFilterSelector from '@/components/AdminMember/AdminMemberFilterSelector';
import AdminMemberSearchResult from '@/components/AdminMember/AdminMemberSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const AdminMemberList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilterValue, setSelectedFilterValue] = useState<AdminMemberSelectedFilterValue>({
    name: '',
    phoneNumber: '',
    startDate: '',
    endDate: '',
    ascending: false,
  });

  const [searchResultData, setSearchResultData] = useState<AdminMemberResponse | null>(null);

  const getAdminMemberData = async (page?: number) => {
    try {
      const { name, phoneNumber, startDate, endDate, ascending } = selectedFilterValue;
      const queryParams = new URLSearchParams({
        name,
        phoneNumber,
        startDate,
        endDate,
        ascending: String(ascending),
        page: String(page ? page : 1),
        size: '10',
      });
      const response = await getAdminMemberList(queryParams);
      setSearchResultData(response.data);
    } catch (error: any) {
      console.log(error);
      setSearchResultData(null);
    }
  };

  useEffect(() => {
    getAdminMemberData(currentPage);
  }, [currentPage, selectedFilterValue.ascending]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const updateAscending = () => {
    setSelectedFilterValue({ ...selectedFilterValue, ascending: !selectedFilterValue.ascending });
  };

  return (
    <>
      <h3 className="text-gray-8">
        관리자 및 데이터 관리 / <span className="font-semibold">관리자 계정 관리</span>
      </h3>
      <AdminMemberFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getAdminMemberData={() => getAdminMemberData(currentPage)}
      ></AdminMemberFilterSelector>
      <AdminMemberSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        getAdminMemberData={() => getAdminMemberData(currentPage)}
        ascending={selectedFilterValue.ascending}
        updateAscending={updateAscending}
      ></AdminMemberSearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default AdminMemberList;
