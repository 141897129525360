import { convertToSelectOptions } from '@/utils/common';
import { Button, Col, Divider, Input, InputNumber, Row, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getJobsDetail, patchJobs, postJobs } from '@/api/public';
import { NO_REQUIRED_VALUE_ERROR_MESSAGE } from '@/const/errorMessage';
import { jobEnumAtom } from '@/store/job';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation, useQuery } from 'react-query';

const { TextArea } = Input;

function JobForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const labelStyle: React.CSSProperties = { padding: '8px 0', fontWeight: '700' };
  const style: React.CSSProperties = { padding: '8px 0' };

  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);

  const [jobFilterInfo, setJobFilterInfo] = useAtom(jobEnumAtom);

  const convertJobFilterInfoToSelectOptions = (jobFilterInfo: JobFilterInfoType) => {
    return {
      status: convertToSelectOptions(jobFilterInfo.status),
      salaryType: convertToSelectOptions(jobFilterInfo.salaryType),
      workingArea: convertToSelectOptions(jobFilterInfo.workingArea),
      workingDays: convertToSelectOptions(jobFilterInfo.workingDays),
      workingHours: convertToSelectOptions(jobFilterInfo.workingHours),
      period: convertToSelectOptions(jobFilterInfo.period),
    };
  };

  const selectOptions = convertJobFilterInfoToSelectOptions(jobFilterInfo);

  const [prevData, setPrevData] = useState<SelectedJobValue>({
    title: '',
    minTons: null,
    maxTons: null,
    loadedInnerLength: null,
    detailContents: '',
    period: null,
    salary: null,
    salaryType: null,
    transportItem: '',
    transportSection: '',
    workingArea: null,
    workingDays: null,
    workingHours: null,
    workingStartHour: null,
    workingEndHour: null,
    status: null,
  });

  const [selectedValue, setSelectedValue] = useState<SelectedJobValue>({
    title: '',
    minTons: null,
    maxTons: null,
    loadedInnerLength: null,
    detailContents: '',
    period: null,
    salary: null,
    salaryType: null,
    transportItem: '',
    transportSection: '',
    workingArea: null,
    workingDays: null,
    workingHours: null,
    workingStartHour: null,
    workingEndHour: null,
    status: null,
  });

  const onChangeSelect = (name: string, value: string | boolean) => {
    setIsDisabledEditBtn(false);
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const onChangeInput = (name: string, value: number | string | null) => {
    setIsDisabledEditBtn(false);
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const postJobMutation = useMutation((requestData: SelectedJobValue) => postJobs(requestData), {
    onSuccess: () => {
      message.success('일자리가 등록되었습니다.', 2);
      setIsDisabledEditBtn(true);
      onClickGoToList();
    },
    onError: (error: AxiosError) => {
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    },
  });

  const onClickRegister = () => {
    const {
      title,
      minTons,
      maxTons,
      loadedInnerLength,
      detailContents,
      period,
      salary,
      salaryType,
      transportItem,
      transportSection,
      workingArea,
      workingDays,
      workingEndHour,
      workingHours,
      workingStartHour,
      status,
    } = selectedValue;

    const request = {
      title,
      minTons,
      maxTons,
      loadedInnerLength,
      detailContents,
      period,
      salary,
      salaryType,
      transportItem,
      transportSection,
      workingArea,
      workingDays,
      workingEndHour,
      workingHours,
      workingStartHour,
      status,
    };

    if (isValidateChecked()) {
      postJobMutation.mutate(request);
    }
  };

  const getJobDetails = async () => {
    try {
      const response = await getJobsDetail(id);
      const res = response.data;
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const getJobDetailQuery = useQuery(['get-job-detail'], getJobDetails, {
    staleTime: 1000 * 60 * 10,
    enabled: !!id,
    onSuccess: (data) => {
      const {
        title,
        minTons,
        maxTons,
        loadedInnerLength,
        detailContents,
        period,
        salary,
        salaryType,
        transportItem,
        transportSection,
        workingArea,
        workingDays,
        workingEndHour,
        workingHours,
        workingStartHour,
        status,
      } = data;

      setPrevData({
        ...selectedValue,
        title,
        minTons,
        maxTons,
        loadedInnerLength,
        detailContents,
        period: period?.code,
        salary,
        salaryType: salaryType?.code,
        transportItem,
        transportSection,
        workingArea: workingArea?.code,
        workingDays: workingDays?.code,
        workingHours: workingHours?.code,
        workingStartHour,
        workingEndHour,
        status: status.code,
      });

      setSelectedValue({
        ...selectedValue,
        title,
        minTons,
        maxTons,
        loadedInnerLength,
        detailContents,
        period: period?.code,
        salary,
        salaryType: salaryType?.code,
        transportItem,
        transportSection,
        workingArea: workingArea?.code,
        workingDays: workingDays?.code,
        workingHours: workingHours?.code,
        workingStartHour,
        workingEndHour,
        status: status.code,
      });
    },
  });

  useEffect(() => {
    if (id) {
      getJobDetailQuery.refetch();
    }
  }, [id]);

  const onClickGoToList = () => {
    navigate('/job');
  };

  const patchJobMutation = useMutation((requestData: SelectedBannerValue) => patchJobs(String(id), requestData), {
    onSuccess: (data) => {
      message.success('일자리 정보가 수정되었습니다.', 2);
      setIsDisabledEditBtn(true);
      const {
        title,
        minTons,
        maxTons,
        loadedInnerLength,
        detailContents,
        period,
        salary,
        salaryType,
        transportItem,
        transportSection,
        workingArea,
        workingDays,
        workingEndHour,
        workingHours,
        workingStartHour,
        status,
      } = data.data;

      setPrevData({
        ...selectedValue,
        title,
        minTons,
        maxTons,
        loadedInnerLength,
        detailContents,
        period: period?.code,
        salary,
        salaryType: salaryType?.code,
        transportItem,
        transportSection,
        workingArea: workingArea?.code,
        workingDays: workingDays?.code,
        workingHours: workingHours?.code,
        workingStartHour,
        workingEndHour,
        status: status?.code,
      });
      onClickGoToList();
    },
    onError: (error: AxiosError) => {
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    },
  });

  const onClickUpdate = () => {
    const request: any = {};

    if (prevData?.title !== selectedValue.title) {
      request.title = selectedValue.title;
    }
    if (prevData?.minTons !== selectedValue.minTons) {
      request.minTons = selectedValue.minTons;
    }
    if (prevData?.maxTons !== selectedValue.maxTons) {
      request.maxTons = selectedValue.maxTons;
    }
    if (prevData?.loadedInnerLength !== selectedValue.loadedInnerLength) {
      request.loadedInnerLength = selectedValue.loadedInnerLength;
    }
    if (prevData?.transportItem !== selectedValue.transportItem) {
      request.transportItem = selectedValue.transportItem;
    }
    if (prevData?.transportSection !== selectedValue.transportSection) {
      request.transportSection = selectedValue.transportSection;
    }
    if (prevData?.workingArea !== selectedValue.workingArea) {
      request.workingArea = selectedValue.workingArea;
    }
    if (prevData?.period !== selectedValue.period) {
      request.period = selectedValue.period;
    }
    if (prevData?.workingDays !== selectedValue.workingDays) {
      request.workingDays = selectedValue.workingDays;
    }
    if (prevData?.workingHours !== selectedValue.workingHours) {
      request.workingHours = selectedValue.workingHours;
    }
    if (prevData?.workingStartHour !== selectedValue.workingStartHour) {
      request.workingStartHour = selectedValue.workingStartHour;
    }
    if (prevData?.workingEndHour !== selectedValue.workingEndHour) {
      request.workingEndHour = selectedValue.workingEndHour;
    }
    if (prevData?.salaryType !== selectedValue.salaryType) {
      request.salaryType = selectedValue.salaryType;
    }
    if (prevData?.salary !== selectedValue.salary) {
      request.salary = selectedValue.salary;
    }
    if (prevData?.detailContents !== selectedValue.detailContents) {
      request.detailContents = selectedValue.detailContents;
    }
    if (prevData?.status !== selectedValue.status) {
      request.status = selectedValue.status;
    }

    if (isValidateChecked()) {
      patchJobMutation.mutate(request);
    }
  };

  const isValidateChecked = () => {
    const {
      title,
      minTons,
      maxTons,
      loadedInnerLength,
      salary,
      salaryType,
      transportItem,
      transportSection,
      workingDays,
      workingEndHour,
      workingHours,
      workingStartHour,
      status,
    } = selectedValue;

    const valuesToCheckJobInfo = [
      title,
      minTons,
      maxTons,
      loadedInnerLength,
      transportItem,
      transportSection,
      workingDays,
      workingHours,
      workingStartHour,
      workingEndHour,
      salaryType,
      salary,
      status,
    ];

    if (valuesToCheckJobInfo.some((value) => value == null || value === '' || value === 0)) {
      message.error(NO_REQUIRED_VALUE_ERROR_MESSAGE, 2);
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>제목
          </div>
        </Col>
        <Col className="gutter-row" span={13}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="제목 입력"
              style={{ width: '100%' }}
              value={selectedValue?.title || ''}
              onChange={(e) => onChangeInput('title', String(e.target.value))}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>상태
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="my-2 mr-4"
              style={{ width: 120 }}
              placeholder="상태"
              optionFilterProp="children"
              options={selectOptions.status}
              value={selectedValue?.status}
              onChange={(value) => onChangeSelect('status', value)}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>최소 톤수
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="최소 톤수"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.minTons}
              onChange={(value) => onChangeInput('minTons', value)}
              min={0}
              max={30}
            />
            <span className="mr-4">t</span>
          </div>
        </Col>

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>최대 톤수
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="최대 톤수"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.maxTons}
              onChange={(value) => onChangeInput('maxTons', value)}
              min={0}
              max={30}
            />
            <span className="mr-4">t</span>
          </div>
        </Col>

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>적재함 길이
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="적재함 길이"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.loadedInnerLength}
              onChange={(value) => onChangeInput('loadedInnerLength', value)}
              min={0}
            />
            <span className="mr-4">m</span>
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>운송 품목
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="운송 품목 입력"
              style={{ width: '100%' }}
              value={selectedValue?.transportItem}
              onChange={(e) => onChangeInput('transportItem', String(e.target.value))}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>운송 구간
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="운송 구간 입력"
              style={{ width: '100%' }}
              value={selectedValue?.transportSection}
              onChange={(e) => onChangeInput('transportSection', String(e.target.value))}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>근무 요일
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="my-2 mr-4"
              style={{ width: 120 }}
              placeholder="근무 요일"
              optionFilterProp="children"
              options={selectOptions.workingDays}
              value={selectedValue?.workingDays}
              onChange={(value) => onChangeSelect('workingDays', value)}
            />
          </div>
        </Col>

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>근무 시간
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="my-2 mr-4"
              style={{ width: 120 }}
              placeholder="근무 시간"
              optionFilterProp="children"
              options={selectOptions.workingHours}
              value={selectedValue?.workingHours}
              onChange={(value) => onChangeSelect('workingHours', value)}
            />
          </div>
        </Col>

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>근무 시작/종료 시간
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="시작 시간"
              style={{ width: 60 }}
              value={selectedValue?.workingStartHour}
              onChange={(value) => onChangeInput('workingStartHour', value)}
              min={0}
              max={24}
            />
            <span className="mr-4">시 ~</span>
            <InputNumber
              className="mr-1"
              placeholder="종료 시간"
              style={{ width: 60 }}
              value={selectedValue?.workingEndHour}
              onChange={(value) => onChangeInput('workingEndHour', value)}
              min={0}
              max={24}
            />
            <span className="mr-4">시</span>
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>급여 방식
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="my-2 mr-4"
              style={{ width: 200 }}
              placeholder="급여 방식"
              optionFilterProp="children"
              options={selectOptions.salaryType}
              value={selectedValue?.salaryType}
              onChange={(value) => onChangeSelect('salaryType', value)}
            />
          </div>
        </Col>

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>
            <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>급여
          </div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <InputNumber
              className="mr-1"
              placeholder="급여"
              style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
              value={selectedValue?.salary}
              onChange={(value) => onChangeInput('salary', value)}
              min={0}
            />
            <span className="mr-4">만원</span>
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>근무 지역</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="my-2 mr-4"
              style={{ width: 120 }}
              placeholder="근무 지역"
              optionFilterProp="children"
              options={selectOptions.workingArea}
              value={selectedValue?.workingArea}
              onChange={(value) => onChangeSelect('workingArea', value)}
            />
          </div>
        </Col>

        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>근무 기간</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="my-2 mr-4"
              style={{ width: 120 }}
              placeholder="근무 기간"
              optionFilterProp="children"
              options={selectOptions.period}
              value={selectedValue?.period}
              onChange={(value) => onChangeSelect('period', value)}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>상세 내용</div>
        </Col>
        <Col className="gutter-row" span={13}>
          <div style={style}>
            <TextArea
              value={selectedValue.detailContents || ''}
              onChange={(e) => onChangeInput('detailContents', e.target.value)}
              placeholder="상세 내용 입력"
              autoSize={{ minRows: 8, maxRows: 8 }}
            />
          </div>
        </Col>
      </Row>

      <Divider orientation="left"></Divider>

      {id ? (
        <div className="flex justify-end">
          <Button onClick={onClickGoToList}>취소</Button>
          <Button
            type="primary"
            className="bg-[#1890ff] mx-2 w-20"
            onClick={onClickUpdate}
            disabled={isDisabledEditBtn}
          >
            수정하기
          </Button>
        </div>
      ) : (
        <div className="flex justify-end">
          <Button onClick={onClickGoToList}>취소</Button>
          <Button
            type="primary"
            className="bg-[#1890ff] mx-2 w-20"
            onClick={onClickRegister}
            disabled={isDisabledEditBtn}
          >
            등록하기
          </Button>
        </div>
      )}
    </>
  );
}

export default JobForm;
