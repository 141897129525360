// atoms.ts
import { atom, useSetAtom } from 'jotai';

interface Member {
  loginId: string;
  id: number;
  name: string;
  phoneNumber: string;
  createdDate: string;
  adminAction: IdNamePair[];
  adminMenu: AdminMenu[];
  adminRole: AdminRoleFilterType;
  role: EnumPresenter;
}

export const memberAtom = atom<Member>({
  loginId: '',
  id: 0,
  name: '',
  phoneNumber: '',
  createdDate: '',
  adminAction: [],
  adminMenu: [],
  adminRole: {} as AdminRoleFilterType,
  role: { code: '', desc: '' },
});

export function useSetMemberAtom() {
  return useSetAtom(memberAtom);
}

export const membersEnumAtom = atom({
  partners: [],
  socialType: [],
});
