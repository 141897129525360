import { patchTerms, postTerms } from '@/api/public';
import { Button, Col, DatePicker, Divider, Input, Row, Select, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { formatValueToDecimalPlaces } from '@/utils/common';
import ko from 'antd/es/date-picker/locale/ko_KR';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import TinyMCEEditor from '../Common/TinyMCEEditor';

interface TermsDataType {
  termsType: string | null;
  version: string | null;
  effectiveDate: string | null;
  contents: string | null;
}

const TermsForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.termsData || {};

  const [termsData, setTermsData] = useState<TermsDataType>({
    termsType: null,
    version: '',
    effectiveDate: '',
    contents: '',
  });

  const [prevData, setPrevData] = useState({
    termsType: data.termsType?.code,
    version: data.version,
    effectiveDate: data.effectiveDate,
    contents: data.contents,
  });

  useEffect(() => {
    if (data) {
      setTermsData({
        termsType: data.termsType?.code || data.termsType || null,
        version: data.version,
        effectiveDate: data.effectiveDate,
        contents: data.contents,
      });
    }
  }, [id]);

  const postTermsMutation = useMutation(postTerms, {
    onSuccess: () => {
      message.success('이용약관이 등록되었습니다.', 2);
      onClickGoToList();
    },
    onError: (error: AxiosError) => {
      if (error.message) {
        message.error(error.message, 2);
      } else {
        console.log(error);
      }
    },
  });

  const patchTermsMutation = useMutation((requestData) => patchTerms(String(id), requestData), {
    onSuccess: () => {
      onClickGoToList();
    },
    onError: () => {},
  });

  const onClickUpdate = () => {
    const request: any = {};

    if (prevData?.contents !== termsData.contents) {
      request.contents = termsData.contents;
    }
    if (prevData?.effectiveDate !== termsData.effectiveDate) {
      request.effectiveDate = termsData.effectiveDate;
    }

    patchTermsMutation.mutate(request);
  };

  const onClickRegister = () => {
    postTermsMutation.mutate({ ...termsData });
  };

  const onClickGoToList = () => {
    navigate('/terms-management');
  };

  const handleChangeContents = (value: string) => {
    setTermsData({ ...termsData, contents: value });
  };

  const onChangeDate = (value: Dayjs | null) => {
    const date = String(value?.format('YYYY-MM-DD'));

    setTermsData({ ...termsData, effectiveDate: value ? date : null });
  };

  const handleChangeType = (value: string) => {
    setTermsData({ ...termsData, termsType: value });
  };

  const handleChangeVersion = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const formattedValue = formatValueToDecimalPlaces(value, 1);

    setTermsData({ ...termsData, [name]: formattedValue });
  };

  const disabledDate = (current: Dayjs | null): boolean => {
    return current ? current.isBefore(dayjs().startOf('day')) : false;
  };

  const isDisabledButton = () => {
    return !termsData.contents || !termsData.effectiveDate || !termsData.termsType || !termsData.version;
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={4}>약관 타입</Col>
        <Col span={20}>
          <Select
            className="my-2 mr-4"
            style={{ width: 200 }}
            placeholder="약관 타입"
            optionFilterProp="children"
            options={[
              { label: '서비스 이용약관', value: 'USE_TERMS' },
              { label: '개인정보 처리방침', value: 'PERSONAL_INFO_TERMS' },
              { label: '마케팅 정보 수신 동의 약관', value: 'MARKETING' },
            ]}
            value={termsData.termsType}
            onChange={(value) => handleChangeType(value)}
            disabled={!!id}
          />
        </Col>
      </Row>
      <Row gutter={16} className="flex items-center">
        <Col span={4}>
          <div style={{ padding: '8px 0' }}>약관 버전</div>
        </Col>

        <Col span={20}>
          <Input
            placeholder="이용약관 버전"
            name="version"
            value={termsData.version || ''}
            onChange={(e) => handleChangeVersion(e)}
            style={{ width: 200 }}
            maxLength={5}
            disabled={!!id}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={4}>시행 일자</Col>
        <Col span={20}>
          <DatePicker
            className="my-2 mr-4"
            style={{ width: 200 }}
            locale={ko}
            value={termsData.effectiveDate ? dayjs(termsData.effectiveDate) : null}
            onChange={(value) => onChangeDate(value)}
            format="YYYY-MM-DD"
            disabledDate={(current) => disabledDate(current)}
          />
        </Col>
      </Row>

      <Row gutter={16} className="flex items-center">
        <Col span={4}>
          <div style={{ padding: '8px 0' }}>이용약관 내용</div>
        </Col>
        <Col span={20}>
          <TinyMCEEditor value={termsData?.contents || ''} onChange={handleChangeContents}></TinyMCEEditor>
        </Col>
      </Row>

      <Divider />

      {id ? (
        <div className="flex justify-end">
          <Button onClick={onClickGoToList}>취소</Button>
          <Button
            type="primary"
            className="bg-[#1890ff] mx-2 w-20"
            disabled={isDisabledButton()}
            onClick={onClickUpdate}
          >
            수정하기
          </Button>
        </div>
      ) : (
        <div className="flex justify-end">
          <Button onClick={onClickGoToList}>취소</Button>
          <Button type="primary" onClick={onClickRegister}>
            등록하기
          </Button>
        </div>
      )}
    </>
  );
};

export default TermsForm;
