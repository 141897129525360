import { getTerms } from '@/api/public';
import TermsFilterSelector from '@/components/Terms/TermsFilterSelector';
import TermsSearchResult from '@/components/Terms/TermsSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const TermsManagement = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedTermsFilterValue>({
    termsType: null,
    startDate: null,
    endDate: null,
  });
  const [searchResultData, setSearchResultData] = useState<PaginatedResponse<Terms> | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getTermsData = async (page?: number) => {
    try {
      const { termsType, startDate, endDate } = selectedFilterValue;

      const queryParams = new URLSearchParams({
        termsType: termsType || '',
        startDate: startDate || '',
        endDate: endDate || '',
        page: String(page ? page : 1),
        size: '10',
      });
      const response = await getTerms(queryParams);
      setSearchResultData(response);
    } catch (error) {
      console.log(error);
      setSearchResultData(null);
    }
  };

  useEffect(() => {
    getTermsData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h2 className="hidden">이용약관 관리</h2>
      <h3 className="text-gray-8">
        운영 관리 / <span className="font-semibold">이용약관 관리</span>
      </h3>
      <TermsFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getData={() => getTermsData(currentPage)}
      />
      <TermsSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getNoticeData={() => getTermsData(currentPage)}
      />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default TermsManagement;
