import { postGoogleReviewsReply } from '@/api/public';
import { Button, message, Modal, Table, TableColumnsType } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

interface GoogleReviewsResponseDataProps {
  selectedFilterValue: SelectedGoogleReviewsFilterValue;
  data: GoogleReviewsResponse | null;
  currentPage: number;
  getGoogleReviewsData: () => void;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  reviewId: string;
  authorName: string;
  starRating: number;
  comments: string;
  reply?: string;
  adminMember?: AdminMemberTableDataType;
  createdDate: string;
}

const GoogleReviewsSearchResult = ({ data, currentPage, getGoogleReviewsData }: GoogleReviewsResponseDataProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isReplyModalVisible, setIsReplyModalVisible] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [googleReviewId, setGoogleReviewId] = useState('');
  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '작성자',
      dataIndex: 'authorName',
    },
    {
      title: '별점',
      dataIndex: 'starRating',
    },
    {
      title: '내용',
      dataIndex: 'comments',
    },

    {
      title: '답변 작성자',
      dataIndex: 'adminMember',
      render: (text, record) => record.adminMember?.name,
    },
    {
      title: '작성일자',
      dataIndex: 'createdDate',
    },
    {
      title: '답변',
      dataIndex: 'reply',
      render: (text, record) => (
        <>
          {record.reply ? (
            <>
              <p
                className="mr-3 overflow-hidden font-bold break-all whitespace-no-wrap text-ellipsis"
                style={{
                  maxWidth: '500px',
                  maxHeight: '200px',
                }}
              >
                {record.reply}
              </p>

              <Button onClick={() => handleReplyButtonClick(record)}>수정</Button>
            </>
          ) : (
            <Button onClick={() => handleReplyButtonClick(record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const handleReplyButtonClick = (googleReviewsInfo: any) => {
    setIsReplyModalVisible(true);
    setReplyContent(googleReviewsInfo.reply);
    setGoogleReviewId(googleReviewsInfo.id);
  };

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      reviewId: item.reviewId || '-',
      authorName: item.authorName || '-',
      starRating: item.starRating,
      comments: item.comments || '',
      reply: item.reply,
      adminMember: item.adminMember || null,
      createdDate: item.createdDate || '-',
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const hasSelected = selectedRowKeys.length > 0;
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleReplyModalClose = () => {
    setIsReplyModalVisible(false);
  };

  const handleReplySave = () => {
    handleReplyModalClose();
    const request = {
      id: googleReviewId,
      reply: replyContent,
    };
    postGoogleReviewReplyMutation.mutate(request);
  };

  const postGoogleReviewReplyMutation = useMutation(
    (requestData: { id: string; reply: string }) => postGoogleReviewsReply(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('리뷰에 대한 답변이 작성되었습니다.', 2);

        getGoogleReviewsData();
      },
      onError: (error: AxiosError) => {
        if (error.message) {
          message.error(error.message, 2);
        } else {
          console.log(error);
        }
      },
      onSettled: () => {
        setIsReplyModalVisible(false);
      },
    },
  );

  const onChangeReply = (reply: string) => {
    setReplyContent(reply);
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 요청건수</span> {data?.totalElements}개
          </div>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={false} />
      </div>

      <Modal
        title="답변 보기"
        open={isReplyModalVisible}
        onCancel={handleReplyModalClose}
        footer={[
          <Button key="close" onClick={handleReplyModalClose}>
            닫기
          </Button>,
          <Button key="save" type="primary" className="bg-[#1890ff] mx-2" onClick={handleReplySave}>
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={replyContent}
          onChange={(e) => onChangeReply(e.target.value)}
          placeholder="답변 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
    </>
  );
};

export default GoogleReviewsSearchResult;
