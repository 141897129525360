import { getDashboard, getFunctionActiveReport, getSalesReport } from '@/api/public';
import DashboardSearchResult from '@/components/Dashboard/DashboardSearchResult';
import FunctionActiveReportSearchResult from '@/components/Dashboard/FunctionActiveReportSearchResult';
import SalesReportSearchResult from '@/components/Dashboard/SalesReportSearchResult';
import StatisticsReportSearchResult from '@/components/Dashboard/StatisticsReportSearchResult';
import { ADMIN_ROLE_FORBIDDEN } from '@/const/errorCode';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';

const Dashboard = () => {
  const [isUnauthorized, setIsUnauthorized] = useState(true);
  const [searchSalesReportResultData, setSearchSalesReportResultData] = useState<SalesReportResponse | null>(null);
  const [searchFunctionActiveReportSearchResultData, setSearchFunctionActiveReportSearchResult] =
    useState<FunctionActiveReportResponse | null>(null);

  const [searchDashboardResultData, setSearchDashboardResultData] = useState<DashboardReportResponse | null>(null);

  const getFunctionActiveReportSearchResultData = async () => {
    try {
      const response = await getFunctionActiveReport();
      setSearchFunctionActiveReportSearchResult(response.data);
    } catch (error: any) {
      if (error.status === 401 || error.status === 403) {
        setSearchFunctionActiveReportSearchResult(null);
      }
    }
  };
  const getSalesReportData = async () => {
    try {
      const response = await getSalesReport();
      setSearchSalesReportResultData(response.data);
    } catch (error: any) {
      if (error.status === 401 || error.status === 403) {
        setSearchSalesReportResultData(null);
      }
    }
  };
  const getDashboardData = async () => {
    try {
      const response = await getDashboard();
      setSearchDashboardResultData(response.data);
    } catch (error: any) {
      if (error.status === 401 || error.status === 403) {
        setSearchDashboardResultData(null);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [salesReportRes, functionActiveRes, dashboardRes] = await Promise.all([
          getSalesReport(),
          getFunctionActiveReport(),
          getDashboard(),
        ]);

        setSearchSalesReportResultData(salesReportRes.data);
        setSearchFunctionActiveReportSearchResult(functionActiveRes.data);
        setSearchDashboardResultData(dashboardRes.data);

        setIsUnauthorized(false);
      } catch (error: any) {
        console.log(error);
        if (error === ADMIN_ROLE_FORBIDDEN) {
          setIsUnauthorized(true);
        } else {
          setIsUnauthorized(false);
          message.error(error.message || COMMON_TOAST_ERROR_MESSAGE);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {!isUnauthorized ? (
        <>
          <DashboardSearchResult data={searchDashboardResultData} getDashboardData={getDashboardData} />
          <StatisticsReportSearchResult />
          <SalesReportSearchResult data={searchSalesReportResultData} />
          <FunctionActiveReportSearchResult data={searchFunctionActiveReportSearchResultData} />
        </>
      ) : null}
    </>
  );
};

export default Dashboard;
